<template>
  <div>
    <b-input-group
      class="display-input input-group-merge"
      :class="{ active: modalOpenned, error: state === false }"
      @click.prevent="show()"
    >
      <b-form-input
        :id="id"
        :ref="`${name}-input`"
        :value="multipleOptionsSelectedText(internalValue)"
        class="form-control-merge"
        :name="name"
        :state="state"
        :placeholder="options.length > 0 ? placeholder : $t('dropdown-modal.no-options-available')"
        :disabled="disabled"
        autocomplete="off"
        data-lpignore="true"
        data-form-type="other"
      />
      <b-input-group-append>
        <b-input-group-text>
          <feather-icon :icon="modalOpenned ? 'ChevronUpIcon' : 'ChevronDownIcon'" />
        </b-input-group-text>
      </b-input-group-append>
    </b-input-group>
    <b-modal
      id="modal-dropdown"
      :ref="`${name}-modal`"
      centered
      :title="modalTitle"
      ok-only
      :ok-disabled="!allowEmpty && internalValue.length === 0"
      :hide-header-close="!allowEmpty && internalValue.length === 0"
      :no-close-on-backdrop="!allowEmpty && internalValue.length === 0"
      @hidden="$nextTick(() => { $refs[`${name}-input`].blur() });"
      @show="modalOpenned = true"
      @hide="modalOpenned = false; $emit('selectionEnded', selectedOptions, id)"
    >
      <b-row
        v-if="searchable"
        class="mb-1"
      >
        <b-col>
          <search-input v-model="search" />
        </b-col>
      </b-row>
      <b-row
        v-if="multiple"
        align-h="between"
        class="mb-1"
      >
        <b-col>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            :disabled="allVisibleOptionsSelected || filteredOptions.length === 0"
            @click="selectAllInView"
          >
            {{ $t('dropdown-modal.select-all-visible') }}
          </b-button>
        </b-col>
        <b-col class="d-flex justify-content-end">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-primary"
            :disabled="!atLeastOneVisibleOptionSelected || filteredOptions.length === 0"
            @click="removeAllInView"
          >
            {{ $t('dropdown-modal.remove-all-visible') }}
          </b-button>
        </b-col>
      </b-row>
      <b-list-group>
        <div class="options-wrapper list-group scroll-area">
          <transition name="fade">
            <template v-if="filteredOptions.length > 0">
              <transition-group
                name="staggered-fade"
                tag="div"
                :css="false"
                @before-enter="beforeEnterAnim"
                @enter="enterAnim"
                @leave="leaveAnim"
              >
                <b-list-group-item
                  v-for="(option, index) in filteredOptions"
                  :key="trackBy ? option[trackBy] : option"
                  :data-index="index"
                  class="user-select-none cursor-pointer overflow-hidden"
                  @click="select(option)"
                >
                  <b-card-text>
                    <b-form-checkbox
                      :id="`option-${index}`"
                      v-model="selectedOptions"
                      :name="`option-${index}`"
                      :value="option"
                      class="cursor-pointer label-pointer"
                      @change="select(option)"
                    >
                      <span>{{ getOptionLabel(option) }}</span>
                      <b-badge
                        v-if="option.badge"
                        v-b-tooltip.hover="option.badge.tooltip || ''"
                        :variant="option.badge.variant || 'primary'"
                        class="ml-1"
                      >
                        {{ option.badge.text }}
                      </b-badge>
                    </b-form-checkbox>
                  </b-card-text>
                </b-list-group-item>
              </transition-group>
            </template>
          </transition>
          <transition name="fade">
            <div
              v-if="filteredOptions.length === 0"
              class="d-flex justify-content-center align-content-center align-items-center full-height"
            >
              <b-card-text class="d-flex justify-content-center align-content-center">
                <template v-if="options.length > 0">
                  {{ $t('dropdown-modal.no-results') }}
                </template>
                <template v-else>
                  {{ $t('dropdown-modal.no-options-available') }}
                </template>
              </b-card-text>
            </div>
          </transition>

        </div>
      </b-list-group>
    </b-modal>
  </div>
</template>

<script>
import {
  BListGroup,
  BListGroupItem,
  BCardText,
  BFormInput,
  BFormCheckbox,
  BRow,
  BCol,
  BButton,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupText,
  BBadge,
  VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import * as Velocity from 'velocity-animate'
import SearchInput from '@core/components/search-input/SearchInput.vue'

// #region utils
function isEmpty(opt) {
  if (opt === 0) return false
  if (Array.isArray(opt) && opt.length === 0) return true
  return !opt
}

function includes(str, query) {
  // NOTE (Will): This was taken from https://github.com/FusionContact/vue-multiselect/blob/master/src/multiselectMixin.js
  // I'm unsure why we need to set the falsy types to a string, but there must be a reason

  /* eslint-disable no-param-reassign */
  if (str === undefined) str = 'undefined'
  if (str === null) str = 'null'
  if (str === false) str = 'false'
  /* eslint-enable no-param-reassign */

  const text = str.toString().toLowerCase()
  return text.indexOf(query.trim()) !== -1
}

function filterOptions(options, search, label, customLabel) {
  return options.filter(option => includes(customLabel(option, label), search))
}

function filterGroups(search, label, values, groupLabel, customLabel) {
  return groups =>
    groups.map(group => {
      if (!group[values]) {
        // eslint-disable-next-line no-console
        console.warn(
          '[DropdownModal] Options passed to the dropdown modal do not contain groups, despite the config.',
        )
        return []
      }
      const groupOptions = filterOptions(
        group[values],
        search,
        label,
        customLabel,
      )

      return groupOptions.length
        ? {
          [groupLabel]: group[groupLabel],
          [values]: groupOptions,
        }
        : []
    })
}

function flattenOptions(valuesKey, labelKey) {
  return options =>
    options.reduce((prev, curr) => {
      if (curr[valuesKey] && curr[valuesKey].length) {
        prev.push({
          $groupLabel: curr[labelKey],
          $isLabel: true,
        })
        return prev.concat(curr[valuesKey])
      }
      return prev
    }, [])
}

function stripGroups(options) {
  return options.filter(option => !option.$isLabel)
}

const flow = (...fns) => x => fns.reduce((v, f) => f(v), x)
// #endregion

// Delay between each stage of the choice when animating fade, 0 all together, 50 for an accordeon
const staggingDelay = 0

export default {
  name: 'DropdownModal',
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  components: {
    BListGroup,
    BListGroupItem,
    BCardText,
    BFormInput,
    BFormCheckbox,
    BRow,
    BCol,
    BButton,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupText,
    BBadge,
    SearchInput,
  },
  props: {
    // REQUIRED
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      default: () => [],
      required: true,
    },
    // OPTIONNAL
    multiple: {
      type: Boolean,
      default: false,
    },
    modalTitle: {
      type: String,
      default() {
        return this.$t('dropdown-modal.default-modal-title')
      },
    },
    state: {
      type: Boolean,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default() {
        return this.multiple
          ? this.$t('dropdown-modal.placeholder-plural')
          : this.$t('dropdown-modal.placeholder')
      },
    },
    allowEmpty: {
      type: Boolean,
      default: true,
    },
    searchable: {
      type: Boolean,
      default: true,
    },
    closeOnSelect: {
      type: Boolean,
      default: false,
    },
    value: {
      type: null,
      default() {
        return []
      },
    },
    labelKey: {
      type: String,
      default: null,
    },
    trackBy: {
      type: String,
      default: null,
    },
    customLabel: {
      type: Function,
      default(option, labelKey) {
        if (isEmpty(option)) return ''
        return labelKey ? option[labelKey] : option
      },
    },
    groupValuesKey: {
      type: String,
      default: null,
    },
    groupLabelKey: {
      type: String,
      default: null,
    },
    multipleOptionsSelectedText: {
      type: Function,
      default(options) {
        if (options.length > 1) {
          return this.$t('dropdown-modal.default-multiple-options-text', {
            count: options.length,
            max: this.options.length,
          })
        }
        if (options.length > 0 && this.multiple) {
          return `${this.getOptionLabel(options[0])} (${options.length}/${
            this.options.length
          })`
        }
        return this.getOptionLabel(options[0])
      },
    },
  },
  data() {
    return {
      displayedText: '',
      selectedOptions: [],
      search: '',
      modalOpenned: false,
    }
  },
  computed: {
    internalValue() {
      if (this.value) {
        return Array.isArray(this.value) ? this.value : [this.value]
      }

      return []
    },
    filteredOptions() {
      const search = this.search || ''
      const normalizedSearch = search.toLowerCase().trim()

      let options = this.options ? this.options.concat() : []

      options = this.groupValuesKey
        ? this.filterAndFlat(options, normalizedSearch, this.labelKey)
        : filterOptions(
          options,
          normalizedSearch,
          this.labelKey,
          this.customLabel,
        )

      return options
    },
    valueKeys() {
      if (this.trackBy) {
        return this.internalValue.map(element => element[this.trackBy])
      }

      return this.internalValue
    },
    optionKeys() {
      const options = this.groupValuesKey
        ? this.flatAndStrip(this.options)
        : this.options || []
      return options.map(element =>
        this.customLabel(element, this.labelKey)
          .toString()
          .toLowerCase(),)
    },
    allVisibleOptionsSelected() {
      return this.filteredOptions.every(option => this.isSelected(option))
    },
    atLeastOneVisibleOptionSelected() {
      return (
        this.filteredOptions.filter(option => this.isSelected(option)).length >
        0
      )
    },
  },
  methods: {
    /**
     * Filters and then flattens the options list
     * @param  {Array}
     * @returns {Array} returns a filtered and flat options list
     */
    filterAndFlat(options, search, label) {
      return flow(
        filterGroups(
          search,
          label,
          this.groupValues,
          this.groupLabelKey,
          this.customLabel,
        ),
        flattenOptions(this.groupValues, this.groupLabelKey),
      )(options)
    },
    /**
     * Flattens and then strips the group labels from the options list
     * @param  {Array}
     * @returns {Array} returns a flat options list without group labels
     */
    flatAndStrip(options) {
      return flow(
        flattenOptions(this.groupValues, this.groupLabelKey),
        stripGroups,
      )(options)
    },
    /**
     * Finds out if the given query is already present
     * in the available options
     * @param  {String}
     * @returns {Boolean} returns true if element is available
     */
    isExistingOption(query) {
      return !this.options ? false : this.optionKeys.indexOf(query) > -1
    },
    /**
     * Finds out if the given element is already present
     * in the result value
     * @param  {Object||String||Integer} option passed element to check
     * @returns {Boolean} returns true if element is selected
     */
    isSelected(option) {
      const opt = this.trackBy ? option[this.trackBy] : option
      return this.valueKeys.indexOf(opt) > -1
    },
    /**
     * Finds out if the given option is disabled
     * @param  {Object||String||Integer} option passed element to check
     * @returns {Boolean} returns true if element is disabled
     */
    isOptionDisabled(option) {
      return !!option.$isDisabled
    },
    /**
     * Returns empty string when options is null/undefined
     * Returns tag query if option is tag.
     * Returns the customLabel() results and casts it to string.
     *
     * @param  {Object||String||Integer} Passed option
     * @returns {Object||String}
     */
    getOptionLabel(option) {
      if (isEmpty(option)) return ''
      if (option.isTag) return option.label
      if (option.$isLabel) return option.$groupLabel

      const label = this.customLabel(option, this.labelKey)

      if (isEmpty(label)) return ''
      return label
    },
    /**
     * Add the given option to the list of selected options
     * or sets the option as the selected option.
     * If option is already selected -> remove it from the results.
     *
     * @param  {Object||String||Integer} option to select/deselect
     */
    select(option, keepSelected = false) {
      if (option.$isLabel && this.groupSelect) {
        this.selectGroup(option)
        return
      }
      if (this.disabled || option.$isDisabled || option.$isLabel) return

      if (this.max && this.multiple && this.internalValue.length === this.max) return

      const isSelected = this.isSelected(option)
      if (isSelected && !keepSelected) {
        this.removeElement(option)
        return
      }

      this.$emit('select', option, this.id)

      if (this.multiple) {
        if (isSelected && keepSelected) {
          this.$emit('input', this.internalValue.concat(), this.id)
          this.selectedOptions = this.internalValue.concat()
          return
        }
        this.$emit('input', this.internalValue.concat([option]), this.id)
        this.selectedOptions = this.internalValue.concat([option])
      } else {
        this.$emit('input', option, this.id)
        this.selectedOptions = [option]
      }

      this.focusSearchInput()
      if (this.closeOnSelect) this.hide()
    },
    /**
     * Add the given group options to the list of selected options
     * If all group options are already selected -> remove it from the results.
     *
     * @param  {Object||String||Integer} group to select/deselect
     */
    selectGroup(selectedGroup) {
      const group = this.options.find(
        option => option[this.groupLabelKey] === selectedGroup.$groupLabel,
      )

      if (!group) return

      if (this.wholeGroupSelected(group)) {
        this.$emit('remove', group[this.groupValues], this.id)

        const newValue = this.internalValue.filter(
          option => group[this.groupValues].indexOf(option) === -1,
        )

        this.$emit('input', newValue, this.id)
        this.selectedOptions = newValue
      } else {
        const optionsToAdd = group[this.groupValues].filter(
          option => !(this.isOptionDisabled(option) || this.isSelected(option)),
        )

        this.$emit('select', optionsToAdd, this.id)
        this.$emit('input', this.internalValue.concat(optionsToAdd), this.id)
        this.selectedOptions = this.internalValue.concat(optionsToAdd)
      }

      this.focusSearchInput()
      if (this.closeOnSelect) this.hide()
    },
    /**
     * Helper to identify if all values in a group are selected
     *
     * @param {Object} group to validated selected values against
     */
    wholeGroupSelected(group) {
      return group[this.groupValues].every(
        option => this.isSelected(option) || this.isOptionDisabled(option),
      )
    },
    /**
     * Helper to identify if all values in a group are disabled
     *
     * @param {Object} group to check for disabled values
     */
    wholeGroupDisabled(group) {
      return group[this.groupValues].every(this.isOptionDisabled)
    },
    /**
     * Removes the given option from the selected options.
     * Additionally checks this.allowEmpty prop if option can be removed when
     * it is the last selected option.
     *
     * @param  {type} option description
     * @returns {type}        description
     */
    removeElement(option, shouldClose = true) {
      if (this.disabled) return
      if (option.$isDisabled) return

      const index =
        typeof option === 'object'
          ? this.valueKeys.indexOf(option[this.trackBy])
          : this.valueKeys.indexOf(option)

      this.$emit('remove', option, this.id)
      if (this.multiple) {
        const newValue = this.internalValue
          .slice(0, index)
          .concat(this.internalValue.slice(index + 1))
        this.$emit('input', newValue, this.id)
        this.selectedOptions = newValue
      } else {
        this.$emit('input', null, this.id)
        this.selectedOptions = []
      }

      this.focusSearchInput()

      if (this.selectedOptions.length === 0 && !this.allowEmpty) {
        // eslint-disable-next-line no-param-reassign
        shouldClose = false
      }

      if (this.closeOnSelect && shouldClose) this.hide()
    },
    /**
     * Calls this.removeElement() with the last element
     * from this.internalValue (selected element Array)
     *
     * @fires this#removeElement
     */
    removeLastElement() {
      if (this.blockKeys.indexOf('Delete') !== -1) return

      if (
        this.search.length === 0 &&
        Array.isArray(this.internalValue) &&
        this.internalValue.length
      ) {
        this.removeElement(
          this.internalValue[this.internalValue.length - 1],
          false,
        )
      }
    },
    show() {
      this.$refs[`${this.name}-modal`].show()
      this.selectedOptions = this.internalValue
    },
    hide() {
      this.$refs[`${this.name}-modal`].hide()
    },
    beforeEnterAnim(el) {
      // eslint-disable-next-line no-param-reassign
      el.style['padding-top'] = 0
      // eslint-disable-next-line no-param-reassign
      el.style['padding-bottom'] = 0
      // eslint-disable-next-line no-param-reassign
      el.style.height = 0
    },
    enterAnim(el, done) {
      const delay = el.dataset.index * staggingDelay
      setTimeout(() => {
        Velocity(
          el,
          {
            height: '43px',
            'padding-top': '0.75rem',
            'padding-bottom': '0.75rem',
          },
          { complete: done },
        )
      }, delay)
    },
    leaveAnim(el, done) {
      const delay = el.dataset.index * staggingDelay
      setTimeout(() => {
        Velocity(
          el,
          {
            height: 0,
            'padding-top': 0,
            'padding-bottom': 0,
          },
          { complete: done },
        )
      }, delay)
    },
    selectAllInView() {
      this.focusSearchInput()

      const values = this.internalValue

      this.filteredOptions.forEach(option => {
        const isSelected = this.isSelected(option)
        if (!isSelected) {
          this.$emit('select', option, this.id)
          values.push(option)
        }
      })

      this.$emit('input', values, this.id)
      this.selectedOptions = values
    },
    removeAllInView() {
      this.focusSearchInput()

      let values = this.internalValue

      this.filteredOptions.forEach(option => {
        const isSelected = this.isSelected(option)
        if (isSelected) {
          this.$emit('remove', option, this.id)

          values = values.filter(opt => opt.id !== option.id)
        }
      })

      this.$emit('input', values, this.id)
      this.selectedOptions = values
    },
    focusSearchInput() {
      if (this.$refs.searchInput) {
        this.$nextTick(() => {
          this.$refs.searchInput.focus()
        })
      }
    },
    clearSelection() {
      let clearedSelection = []
      if (!this.multiple) clearedSelection = {}

      this.$emit('input', clearedSelection, this.id)
    },
  },
}
</script>

<!-- Force modal on top -->
<style lang="scss">
#modal-dropdown___BV_modal_outer_ {
  z-index: 3000 !important;
}

#modal-dropdown {
  & label,
  & label::before,
  & label::after {
    cursor: pointer;
  }
  & .options-wrapper {
    max-height: 23.3rem;
    min-height: 23.3rem;
    overflow-y: auto;
  }
}
</style>

<style lang="scss" scoped>
// Color palettes
@import '~@core/scss/base/core/colors/palette-variables.scss';

.display-input {
  cursor: pointer;
  input {
    cursor: pointer !important;
  }
  &.active {
    .form-control-merge {
      border-color: $primary;
    }
    .input-group-text {
      border-color: $primary;
    }
  }
  &.error {
    .input-group-text {
      border-color: $danger;
    }
  }
}
</style>
