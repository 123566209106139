// import constants from '@/libs/utils/constants'
import Vue from 'vue'

export default {
  namespaced: true,
  state: {},
  getters: {},
  actions: {
    GET_INVOICES: () =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .get('/api/invoices')
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    GET_ALL_INVOICES: (state, { params }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .get('/api/invoices', { params })
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    GET_INVOICE: (state, { invoiceId }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .get(`/api/invoices/${invoiceId}`)
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    GET_INVOICE_ITEMS: (state, { invoiceId, params }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .get(`/api/invoices/${invoiceId}/items`, { params })
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    GET_INVOICE_MEETINGS: (
      state,
      {
        invoiceId,
        stateFilter,
        statusFilter,
        shouldPaginate,
        pageNumber,
        pageSize,
        sortBy,
        sortDesc,
      },
    ) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .get(`/api/invoices/${invoiceId}/meetings`, {
            params: {
              stateFilter,
              statusFilter,
              shouldPaginate,
              pageNumber,
              pageSize,
              sortBy,
              sortDesc,
            },
          })
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    ADD_INVOICE_HOURS: (state, { invoiceId, data }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .post(`/api/invoices/${invoiceId}/hours`, data)
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    UPDATE_INVOICE_HOURS: (state, { invoiceId, data }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .put(`/api/invoices/${invoiceId}/hours`, data)
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    UPDATE_INVOICE: (state, { invoiceId, invoiceData }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .put(`/api/invoices/${invoiceId}`, invoiceData)
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    ADD_INVOICE_MEETING: (state, { invoiceId, meetingId }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .post(`/api/invoices/${invoiceId}/meetings/${meetingId}`)
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    REMOVE_INVOICE_MEETING: (state, { invoiceId, meetingId, newStatusId, additionalInfo }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .delete(`/api/invoices/${invoiceId}/meetings/${meetingId}`, {
            data: { newStatusId, additionalInfo },
          })
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    CLOSE_INVOICE: (state, { invoiceId, invoiceData }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .post(`/api/invoices/${invoiceId}/close`, invoiceData)
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
  },
}
