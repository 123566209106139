import Vue from 'vue'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // Params: { provinceList, cityList }
    GET_CITIES: (state, { params }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .get('/api/autofill/cities', { params })
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    // Params: { regionList, cityList }
    GET_PROVINCES: (state, { params }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .get('/api/autofill/provinces', { params })
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    // Params: { regionList, provinceList, cityList }
    GET_POSTAL_CODES: (state, { params }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .get('/api/autofill/postal-codes', { params })
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    // Params: { campaignList }
    GET_SIC: (state, { params }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .get('/api/autofill/sic', { params })
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    // Params: { campaignList }
    GET_FIC: (state, { params }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .get('/api/autofill/fic', { params })
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    // GET_CUSTOMS: (state, { params }) => new Promise((resolve, reject) => {
    //   Vue.prototype.$http.get('/api/filters/customs', { params })
    //     .then(res => resolve(res))
    //     .catch(err => reject(err))
    // }),
  },
}
