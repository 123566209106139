import Vue from 'vue'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    GET_MEETING: (state, { token }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .get('/api/campaigns/-1/meetings/quick-actions/get-meeting', {
            params: {
              token,
            },
          })
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    UPDATE_MEETING_STATUS: (state, { token, statusId, reason }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .post('/api/campaigns/-1/meetings/quick-actions/update-meeting-status', {
            token,
            meetingClientStatusId: statusId,
            reason,
          })
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
  },
}
