import Vue from 'vue'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    GET_AGENTS_FOR_MONTH: (state, { date }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .get(`/api/bonus/agents?month=${date.month}&year=${date.year}`)
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    GET_AGENT_BONUS_FOR_MONTH: (state, { agentId, date }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .get(`/api/bonus/agents/${agentId}?month=${date.month}&year=${date.year}`)
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    UPDATE_BONUS: (state, { bonus }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .put('/api/bonus/agents/bulk', { bonus })
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    CREATE_BONUS: (state, { agentId, bonus }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .post(`/api/bonus/agents/${agentId}`, bonus)
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    DELETE_BONUS: (state, { agentId, bonusId }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .delete(`/api/bonus/agents/${agentId}/bonus/${bonusId}`)
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    MARK_AS_PAID: (state, { month, year }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .put('/api/bonus/agents/mark-as-paid', { month, year })
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    MARK_AS_UNPAID: (state, { month, year }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .put('/api/bonus/agents/mark-as-unpaid', { month, year })
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
  },
}
