import Vue from 'vue'
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'
import IconifyIcon from '@core/components/iconify-icon/IconifyIcon.vue'
import DropdownModal from '@core/components/dropdown-modal/DropdownModal.vue'
import PerfectScrollbar from 'vue2-perfect-scrollbar'

import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'

Vue.component(FeatherIcon.name, FeatherIcon)
Vue.component(IconifyIcon.name, IconifyIcon)
Vue.component(DropdownModal.name, DropdownModal)
Vue.use(PerfectScrollbar)
