import { getUserData } from '@/auth/utils'
import moment from 'moment'

export default [
  // {
  //   path: '/apps/calendar',
  //   name: 'apps-calendar',
  //   component: () => import('@/views/apps/calendar/Calendar.vue'),
  // },

  // *===============================================---*
  // *--------- USER ---- ---------------------------------------*
  // *===============================================---*
  // {
  //   path: '/apps/users/list',
  //   name: 'apps-users-list',
  //   component: () => import('@/views/apps/user/users-list/UsersList.vue'),
  // },
  // {
  //   path: '/apps/users/view/:id',
  //   name: 'apps-users-view',
  //   component: () => import('@/views/apps/user/users-view/UsersView.vue'),
  // },
  // {
  //   path: '/apps/users/edit/:id',
  //   name: 'apps-users-edit',
  //   component: () => import('@/views/apps/user/users-edit/UsersEdit.vue'),
  // },

  // *===============================================---*
  // *--------- CRM ---------------------------------------*
  // *===============================================---*
  {
    path: '/administration',
    name: 'administration',
    // QA: new-prospect, ADMIN: meeting-approval
    redirect: {
      name: getUserData()?.role === 'qa' ? 'new-prospect' : 'meeting-approval' ?? 'new-prospect',
    },
    component: () => import('@/views/apps/administration/AdminSection.vue'),
    meta: {
      resource: 'qa',
      action: 'read',
    },
    children: [
      {
        path: 'meeting-approval',
        name: 'meeting-approval',
        component: () =>
          import('@/views/apps/administration/components/MeetingApproval/MeetingApproval.vue'),
        meta: {
          resource: 'administration',
          action: 'read',
        },
      },
      {
        path: 'user-management',
        name: 'user-management',
        component: () =>
          import('@/views/apps/administration/components/UserManagement/UserManagement.vue'),
        meta: {
          resource: 'administration',
          action: 'read',
        },
      },
      {
        path: 'campaign-management',
        name: 'campaign-management',
        component: () =>
          import(
            '@/views/apps/administration/components/CampaignManagement/CampaignManagement.vue'
          ),
        meta: {
          resource: 'administration',
          action: 'read',
        },
      },
      {
        path: 'data-import',
        name: 'data-import',
        component: () => import('@/views/apps/administration/components/DataImport/DataImport.vue'),
        meta: {
          resource: 'administration',
          action: 'read',
        },
      },
      {
        path: 'new-prospect',
        name: 'new-prospect',
        component: () =>
          import('@/views/apps/administration/components/NewProspect/NewProspect.vue'),
        meta: {
          resource: 'qa',
          action: 'read',
        },
      },
      {
        path: 'bonus',
        name: 'bonus',
        component: () => import('@/views/apps/administration/components/Bonus/Bonus.vue'),
        meta: {
          resource: 'administration',
          action: 'read',
        },
      },
      {
        path: 'employee-schedule-management',
        name: 'employee-schedule-management',
        component: () =>
          import(
            '@/views/apps/administration/components/EmployeeScheduleManagement/EmployeeScheduleManagement.vue'
          ),
        meta: {
          resource: 'administration',
          action: 'read',
        },
      },
      {
        path: 'projects',
        name: 'projects',
        redirect: { name: 'projects-list' },
        component: () =>
          import('@/views/apps/administration/components/ProjectManagement/ProjectManagement.vue'),
        meta: {
          resource: 'administration',
          action: 'read',
        },
        children: [
          {
            path: '',
            name: 'projects-list',
            component: () =>
              import(
                '@/views/apps/administration/components/ProjectManagement/ProjectList/ProjectList.vue'
              ),
            meta: {
              resource: 'administration',
              action: 'read',
            },
          },
          {
            path: ':projectId',
            name: 'project-view',
            component: () =>
              import(
                '@/views/apps/administration/components/ProjectManagement/ProjectView/ProjectView.vue'
              ),
            meta: {
              resource: 'administration',
              action: 'read',
            },
          },
        ],
      },
      {
        path: 'clients',
        name: 'clients',
        redirect: { name: 'clients-table' },
        component: () =>
          import('@/views/apps/administration/components/ClientProfile/ClientsManagement.vue'),
        meta: {
          resource: 'administration',
          action: 'read',
        },
        children: [
          {
            path: '',
            name: 'clients-table',
            component: () =>
              import('@/views/apps/administration/components/ClientProfile/ClientsList.vue'),
            meta: {
              resource: 'administration',
              action: 'read',
            },
          },
          {
            path: ':clientId',
            name: 'client-profile',
            redirect: { name: 'client-infos' },
            component: () =>
              import('@/views/apps/administration/components/ClientProfile/ClientProfile.vue'),
            meta: {
              resource: 'administration',
              action: 'read',
            },
            children: [
              {
                path: 'infos',
                name: 'client-infos',
                component: () =>
                  import(
                    '@/views/apps/administration/components/ClientProfile/components/ClientInfosTab.vue'
                  ),
                meta: {
                  resource: 'administration',
                  action: 'read',
                },
              },
              {
                path: 'invoices',
                name: 'client-invoices',
                component: () =>
                  import(
                    '@/views/apps/administration/components/ClientProfile/components/ClientInvoicesTab.vue'
                  ),
                meta: {
                  resource: 'administration',
                  action: 'read',
                },
              },
              {
                path: 'invoices/:invoiceId',
                name: 'client-invoice-view',
                component: () =>
                  import(
                    '@/views/apps/administration/components/ClientProfile/components/invoices/InvoiceView.vue'
                  ),
                meta: {
                  resource: 'administration',
                  action: 'read',
                },
              },
            ],
          },
        ],
      },
      {
        path: 'configs',
        name: 'configurations-management',
        redirect: { name: 'provinces-configuration' },
        component: () =>
          import(
            '@/views/apps/administration/components/ConfigurationsManagement/ConfigurationsManagement.vue'
          ),
        meta: {
          resource: 'qa',
          action: 'read',
        },
        children: [
          {
            path: 'provinces',
            name: 'provinces-configuration',
            component: () =>
              import(
                '@/views/apps/administration/components/ConfigurationsManagement/ProvincesConfig/ProvincesConfig.vue'
              ),
            meta: {
              resource: 'qa',
              action: 'read',
            },
          },
          {
            path: 'regions',
            name: 'regions-configuration',
            component: () =>
              import(
                '@/views/apps/administration/components/ConfigurationsManagement/RegionsConfig/RegionsConfig.vue'
              ),
            meta: {
              resource: 'qa',
              action: 'read',
            },
          },
          {
            path: 'cities',
            name: 'cities-configuration',
            component: () =>
              import(
                '@/views/apps/administration/components/ConfigurationsManagement/CitiesConfig/CitiesConfig.vue'
              ),
            meta: {
              resource: 'qa',
              action: 'read',
            },
          },
          {
            path: 'postal-codes',
            name: 'postal-codes-configuration',
            component: () =>
              import(
                '@/views/apps/administration/components/ConfigurationsManagement/PostalCodesConfig/PostalCodesConfig.vue'
              ),
            meta: {
              resource: 'qa',
              action: 'read',
            },
          },
          {
            path: 'contact-titles',
            name: 'contact-titles-configuration',
            component: () =>
              import(
                '@/views/apps/administration/components/ConfigurationsManagement/ContactTitlesConfig/ContactTitlesConfig.vue'
              ),
            meta: {
              resource: 'qa',
              action: 'read',
            },
          },
          {
            path: 'clipboards',
            name: 'clipboards-configuration',
            component: () =>
              import(
                '@/views/apps/administration/components/ConfigurationsManagement/ClipboardsConfig/ClipboardsConfig.vue'
              ),
            meta: {
              resource: 'qa',
              action: 'read',
            },
          },
        ],
      },
    ],
  },
  {
    path: '/statistics',
    name: 'statistics',
    redirect: { name: 'campaigns-statistics' },
    component: () => import('@/views/apps/statistics/StatisticsSection.vue'),
    meta: {
      resource: 'statistics',
      action: 'read',
    },
    children: [
      {
        path: 'bonus',
        name: 'bonus-list',
        redirect: { name: 'bonuses-at-date', params: { selectedDate: moment().format('YYYY-MM') } },
        component: () => import('@/views/apps/statistics/AdminBonusTab.vue'),
        meta: {
          resource: 'statistics',
          action: 'read',
        },
        children: [
          {
            path: ':selectedDate',
            name: 'bonuses-at-date',
            component: () => import('@/views/apps/statistics/AdminBonusList.vue'),
            meta: {
              resource: 'statistics',
              action: 'read',
            },
          },
          {
            path: ':selectedDate/agent/:agentId',
            name: 'bonus-statistics',
            component: () => import('@/views/apps/statistics/MyBonuses.vue'),
            meta: {
              resource: 'statistics',
              action: 'read',
            },
          },
        ],
      },
      {
        path: 'campaigns-statistics',
        name: 'campaigns-statistics',
        component: () => import('@/views/apps/statistics/AdminCampaignStats.vue'),
        meta: {
          resource: 'statistics',
          action: 'read',
        },
      },
      {
        path: 'meetings',
        name: 'stats-meetings-table',
        component: () => import('@/views/apps/statistics/AdminMeetingsTable.vue'),
        meta: {
          resource: 'statistics',
          action: 'read',
        },
      },
    ],
  },
  {
    path: '/campaigns',
    name: 'campaigns-list',
    component: () => import('@/views/apps/campaigns/CampaignsList.vue'),
    meta: {
      resource: 'campaigns',
      action: 'read',
    },
  },
  {
    path: '/campaigns/:campaignId',
    name: 'campaign-view',
    component: () => import('@/views/apps/campaigns/TechnicalSheet.vue'),
    meta: {
      resource: 'campaigns',
      action: 'read',
    },
  },
  {
    path: '/worksheet/:campaignLnkProspectId',
    name: 'worksheet',
    component: () => import('@/views/apps/worksheet/WorkSheetView.vue'),
    meta: {
      resource: 'worksheet',
      action: 'read',
      disableNavbarShadow: true,
    },
  },
  {
    path: '/worksheet/:campaignLnkProspectId/technical-sheet',
    name: 'worksheet',
    component: () => import('@/views/apps/worksheet/WorkSheetView.vue'),
    meta: {
      resource: 'worksheet',
      action: 'read',
      disableNavbarShadow: true,
    },
  },
  {
    path: '/files',
    name: 'admin-files',
    component: () => import('@/views/apps/administration/components/FileView.vue'),
    meta: {
      resource: 'administration',
      action: 'read',
    },
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/apps/home/HomeOverview.vue'),
    meta: {
      resource: 'home',
      action: 'read',
    },
  },
  {
    path: '/tickets',
    name: 'tickets',
    component: () => import('@/views/apps/tickets/Table/TicketsTable.vue'),
    meta: {
      resource: 'tickets',
      action: 'read',
    },
  },
  {
    path: '/tickets/:ticketId',
    name: 'ticket-view',
    component: () => import('@/views/apps/tickets/View/TicketView.vue'),
    meta: {
      resource: 'tickets',
      action: 'read',
    },
  },
  {
    path: '/support-tickets',
    name: 'support-tickets',
    component: () => import('@/views/apps/tickets/supportTickets/Table/TicketsTable.vue'),
    meta: {
      resource: 'support-tickets',
      action: 'read',
    },
  },
  {
    path: '/support-tickets/:ticketId',
    name: 'support-tickets-view',
    component: () => import('@/views/apps/tickets/supportTickets/View/TicketView.vue'),
    meta: {
      resource: 'tickets',
      action: 'read',
    },
  },
  {
    path: '/my-statistics',
    name: 'my-statistics',
    component: () => import('@/views/apps/statistics/MyStatistics.vue'),
    meta: {
      resource: 'my-statistics',
      action: 'read',
    },
  },
  {
    path: '/my-bonuses',
    name: 'my-bonuses',
    component: () => import('@/views/apps/statistics/MyBonuses.vue'),
    meta: {
      resource: 'my-bonuses',
      action: 'read',
    },
  },
  {
    path: '/agent-weekly-schedule',
    name: 'employeeWeeklySchedule',
    component: () => import('@/views/apps/EmployeeWeeklySchedule/EmployeeWeeklySchedule.vue'),
    meta: {
      // resource: 'agentWeeklySchedule',
      resource: 'administration',
      action: 'read',
    },
  },
  {
    path: '/client-portal',
    name: 'clientPortal',
    component: () => import('@/views/apps/client-portal/ClientPortal.vue'),
    meta: {
      resource: 'client-portal',
      action: 'read',
    },
    children: [
      {
        path: '',
        redirect: { name: 'clientPortalMeetings' },
      },
      {
        path: '/client-portal/meetings',
        name: 'clientPortalMeetings',
        component: () => import('@/views/apps/client-portal/Calendar/ClientMeetings.vue'),
        meta: {
          resource: 'client-portal',
          action: 'read',
        },
      },
    ],
  },
  {
    path: '/agent-weekly-schedule/:userId',
    name: 'agentWeeklySchedule',
    component: () => import('@/views/apps/EmployeeWeeklySchedule/EmployeeWeeklySchedule.vue'),
    meta: {
      // resource: 'agentWeeklySchedule',
      resource: 'administration',
      action: 'read',
    },
  },
]
