import { getUserData } from '@/auth/utils'
import Vue from 'vue'

function convertToAPIFormat(event) {
  return {
    username: event.username,
    roleEnum: event.role,
    firstName: event.firstName,
    lastName: event.lastName,
    userMail: event.userMail,
    phone: event.phone,
    initials: event.initials,
    password: event.password,
    confirmedPassword: event.confirmedPassword,
    oldPassword: event.oldPassword,
    newPassword: event.newPassword,
    enabled: event.enabled,
  }
}

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    GET_USER_INFOS: () =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .get('/api/users/me')
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    PUT_USER_UPDATE: (state, { body }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .put(`/api/users/${getUserData().userId}`, { ...body })
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    UPDATE_PREFERENCES: (state, { body }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .put(`/api/users/${getUserData().userId}/preferences`, { ...body })
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    GET_ALL_USERS: (state, { params }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .get('/api/users', { params })
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    PUT_SPECIFIC_USER_UPDATE: (state, { user }) =>
      new Promise((resolve, reject) => {
        const apiEvent = convertToAPIFormat(user)

        Vue.prototype.$http
          .put(`/api/users/${user.userId}`, apiEvent)
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    CREATE_USER: (state, { user }) =>
      new Promise((resolve, reject) => {
        const apiEvent = convertToAPIFormat(user)

        Vue.prototype.$http
          .post('/api/users', apiEvent)
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    REGISTER_CLIENT: (state, { user }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .post('/api/users/register-client', user)
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    ADMIN_UPDATE_USER: (state, { user }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .put(`/api/users/${user.userId}`, user)
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    FORGOT_PASSWORD: (state, { email }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .post('/api/users/forgot-password', { email })
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    RESET_PASSWORD: (state, { password, token }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .post('/api/users/reset-password', { password, token })
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
  },
}
