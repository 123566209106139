import Vue from 'vue'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    GET_BONUSES: (state, { agentId, isMonthly }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .get(`/api/bonus?agentId=${agentId}&isMonthly=${isMonthly}`)
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    UPDATE_BONUSES: (state, { bonuses }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .put('/api/bonus/bulk', { bonuses })
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    CREATE_BONUS: (state, bonus) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .post('/api/bonus', bonus)
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    REMOVE_BONUSES: (state, bonus) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .delete('/api/bonus', { data: bonus })
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    GET_UPDATES: (state, { bonusId }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .get(`/api/bonus/${bonusId}/updates`)
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    UPDATE_A_BONUS_UPDATE: (state, { currentBonus, bonus }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .patch(`/api/bonus/${currentBonus.bonusId}/updates/${bonus.bonusId}`, {
            ...bonus,
            userId: bonus.userId || -1,
          })
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    DELETE_A_BONUS_UPDATE: (state, { currentBonus, bonus }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .delete(`/api/bonus/${currentBonus.bonusId}/updates/${bonus.bonusId}`)
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
  },
}
