import moment from 'moment'
import { getUserData } from '@/auth/utils'
import constants from './constants'

export default {
  getQueryStringFromArrayOfObjectWithKey(arr, key) {
    let queryStr = '-1'
    if (arr.filter(e => e[key] === -1).length > 0) {
      return queryStr
    }

    if (arr !== undefined && arr.length > 1) {
      queryStr = arr.map(i => i[key]).join(',')
    } else if (arr.length === 1) {
      if (arr[0][key]) {
        queryStr = arr[0][key].toString()
      } else {
        // Make sure to return -1 if the first element of the array is unrecognizable
        queryStr = '-1'
      }
    }
    return queryStr
  },
  makeErrorMessage: (
    context,
    errorDescriptionI18nTag,
    errorObject,
    showNotification = true,
    additionnalValues = {},
  ) => {
    if (!errorObject) {
      // eslint-disable-next-line no-console
      console.error(
        '[ERROR Manager] The "error" object is undefined! This is not normal.',
        errorDescriptionI18nTag,
      )
      return
    }

    function isInsideTheErrorsArray(errorsList) {
      return errorsList && errorsList[0]
    }

    function isTranslatableErrorCode(errorCode) {
      return Object.keys(context.$t('errors')).includes(errorCode)
    }

    function isTranslatableErrorCodeArray(errorsList) {
      return isInsideTheErrorsArray(errorsList) && isTranslatableErrorCode(errorsList[0])
    }
    // Get the error message
    let errorMessage = ''
    let errorCode = ''
    const errorResponse = errorObject.response
    if (
      errorObject.code &&
      (errorObject.code === 'ERR_NETWORK' || errorObject.code === 'ECONNABORTED')
    ) {
      errorMessage = `${context.$t('errors.network-error')}`
      errorCode = 'ERR_NETWORK'
    } else if (errorResponse && !errorResponse.data) {
      errorMessage = `${context.$t('errors.internal-lib-error')} ${errorResponse}`
      errorCode = 'INTERNAL'
    } else if (errorResponse && isTranslatableErrorCodeArray(errorResponse.data.errors)) {
      errorMessage = `[${errorResponse.data.errors[0]}] ${context.$t(
        `errors.${errorResponse.data.errors[0]}`,
        additionnalValues,
      )}`
      errorCode = errorResponse.data.errors[0]
    } else if (errorResponse && isTranslatableErrorCode(errorResponse.data.message)) {
      errorMessage = context.$t(`errors.${errorResponse.data.message}`, additionnalValues)
      errorCode = errorResponse.data.message
    } else if (errorResponse && isInsideTheErrorsArray(errorResponse.data.errors)) {
      const prefix = !errorResponse.data.errors[0].includes(' ')
        ? 'Code:'
        : `${context.$t('errors.response-from-database')}:`

      errorMessage = `${prefix} ${errorResponse.data.errors}`
      errorCode = errorResponse.data.errors
    } else if (errorResponse && errorResponse.data && errorResponse.data.title) {
      errorMessage = `${context.$t('errors.response-from-database')}: ${errorResponse.data.title}`
      errorCode = 'DATABASE'
    }

    if (errorMessage === '') {
      errorMessage = context.$t(errorDescriptionI18nTag)
      errorCode = 'UNIDENTIFIED'
    }

    if (showNotification) {
      // Build the toast
      const h = context.$createElement

      const vNodesTitle = h(
        'div',
        { class: ['d-flex', 'flex-grow-1', 'align-items-baseline', 'mr-2'] },
        [
          h('strong', { class: 'mr-2' }, context.$t('errors.title')),
          h('small', { class: 'ml-auto text-italics' }, moment().format('YYYY-MM-DD HH:mm:ss')),
        ],
      )

      const user = getUserData()
      if (user && user.userId && errorCode !== 'ERR_NETWORK') {
        context.$store.dispatch('notifications/CREATE_NEW_NOTIFICATION', {
          notifiedUserId: getUserData().userId,
          title: 'errors.title',
          description: errorMessage,
          icon: 'AlertCircleIcon',
          variant: constants.notificationVariant.Error,
          showToast: false,
        })
      }

      // Display the error
      context.$bvToast.toast(errorMessage, {
        title: [vNodesTitle],
        variant: 'danger',
        solid: true,
      })
    }

    context.$logRocket.captureMessage(errorCode, {
      tags: {
        type: errorCode,
      },
      extra: {
        type: errorCode,
        message: errorMessage,
      },
    })
    // eslint-disable-next-line no-console
    console.error(`[Error Manager] ${errorMessage}`, errorObject)
  },
  getWorkWeekOfDate(date) {
    const curr = date
    const week = []

    for (let i = 1; i <= 7; i += 1) {
      const first = curr.getDate() - curr.getDay() + i
      const day = new Date(curr.setDate(first))
      if (day.getDay() === 1 || day.getDay() === 5) {
        week.push(day)
      }
    }
    return week
  },
  toHHMM(secNum) {
    let hours = Math.floor(secNum / 3600)
    let minutes = Math.floor((secNum - hours * 3600) / 60)

    if (hours < 10) {
      hours = `0${hours}`
    }
    if (minutes < 10) {
      minutes = `0${minutes}`
    }
    return `${hours}h${minutes}`
  },
  openWebsite(website) {
    let websiteFormatted = website
    if (!website.includes('https://') && !website.includes('http://')) {
      websiteFormatted = `http://${website}`
    }
    window.open(websiteFormatted, '_blank')
  },
  isToday(date) {
    const today = new Date()
    return (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    )
  },
  generatePassword() {
    // Generate a complex password of 16 characters
    let newPassword = ''
    const possible =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+~`|}{[]:;?><,./-='
    for (let i = 0; i < 16; i++) {
      newPassword += possible.charAt(Math.floor(Math.random() * possible.length))
    }
    // Replace a random character with a random number (makes sure there is at least one)
    const randomIndex = Math.floor(Math.random() * 16)
    newPassword =
      newPassword.substring(0, randomIndex) +
      Math.floor(Math.random() * 10) +
      newPassword.substring(randomIndex + 1)
    // Replace a random character with a random special character (makes sure there is at least one)
    const randomIndex2 = Math.floor(Math.random() * 16)
    newPassword =
      newPassword.substring(0, randomIndex2) +
      possible.charAt(Math.floor(Math.random() * 31) + 62) +
      newPassword.substring(randomIndex2 + 1)

    return newPassword
  },
  html2clipboard(html, el) {
    if (navigator.clipboard && navigator.clipboard.write) {
      const type = 'text/html'
      const blob = new Blob([html], { type })
      const data = [new ClipboardItem({ [type]: blob })]

      navigator.clipboard.write(data)
    } else {
      let tmpEl
      if (typeof el !== 'undefined') {
        // you may want some specific styling for your content - then provide a custom DOM node
        // with classes, inline styles or whatever you want
        tmpEl = el
      } else {
        // else we'll just create one
        tmpEl = document.createElement('div')
      }

      // fill it with your HTML
      tmpEl.innerHTML = html

      // append the temporary node to the DOM
      document.body.appendChild(tmpEl)

      // select the newly added node
      const range = document.createRange()
      range.selectNode(tmpEl)
      window.getSelection().addRange(range)

      // copy - here we're relying on the browser supporting 'copy' as part
      // of the execCommand() interface, its depricated but still supported in all major browsers
      document.execCommand('copy')

      // and remove the element immediately
      document.body.removeChild(tmpEl)
    }
  },
}
