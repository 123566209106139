/* eslint-disable no-bitwise */
const sunday = 1
const monday = 2
const tuesday = 4
const wednesday = 8
const thursday = 16
const friday = 32
const saturday = 64

const days = [sunday, monday, tuesday, wednesday, thursday, friday, saturday]

const convertMap = {
  [sunday]: 0,
  [monday]: 1,
  [tuesday]: 2,
  [wednesday]: 3,
  [thursday]: 4,
  [friday]: 5,
  [saturday]: 6,
}

export default {
  maskToDays(mask) {
    const resultingDays = []
    days.forEach(day => {
      if ((mask & day) === day) {
        resultingDays.push(convertMap[day])
      }
    })

    return resultingDays
  },
  daysToMask(daysArray) {
    return daysArray.reduce((acc, cur) => acc | days[cur], 0)
  },
}
