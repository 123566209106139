import constants from '@/libs/utils/constants'
import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    filters: {
      search: '',
      status: constants.ticketStatus.Open,
      filter: constants.supportTicketFilter.All
    },
    isEditable: false,
  },
  getters: {
    getIsEditable: state => state.isEditable,
  },
  mutations: {
    setFilters(state, filters) {
      state.filters = filters
    },
    makeSupportTicketEditable(state, isEditable = false) {
      state.isEditable = isEditable
    },
  },
  actions: {
    SET_FILTERS({ commit }, filters) {
      commit('setFilters', filters)
    },
    GET_SUPPORT_TICKETS: (state, { paramsTicket }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .get('/api/support-tickets', { params: paramsTicket })
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    GET_ONE_SUPPORT_TICKET: (state, { id }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .get(`/api/support-tickets/${id}`)
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    CREATE_SUPPORT_TICKET: (state, { ticket }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .post('/api/support-tickets', ticket)
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    GET_ASSIGNABLE_USERS: () =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .get('/api/support-tickets/assignable-users')
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    UPDATE_SUPPORT_TICKET: (state, { id, data }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .patch(`/api/support-tickets/${id}`, data)
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    CREATE_COMMENT: (state, { ticketId, comment }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .post(`/api/support-tickets/${ticketId}/comments`, comment)
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
  },
}
