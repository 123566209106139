import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    currentImportationId: null,
    currentCsvHeaders: [],
  },
  getters: {
    fileIsValidAndUploaded: state => state.currentImportationId !== null,
    csvHeaders: state => state.currentCsvHeaders,
  },
  mutations: {
    clear: state => {
      state.currentImportationId = null
      state.currentCsvHeaders = []
    },
    fileUploaded: (state, { currentImportationId, currentCsvHeaders }) => {
      state.currentImportationId = currentImportationId
      state.currentCsvHeaders = currentCsvHeaders
    },
  },
  actions: {
    GET_FILES: (state, { paramImportation }) =>
      new Promise((resolve, reject) => {
        const params = {
          pageNumber: paramImportation.pageNumber,
          pageSize: paramImportation.pageSize,
          search: paramImportation.search,
        }

        Vue.prototype.$http
          .get('/api/importations', { params })
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    GET_IMPORTATION: (state, { importationId }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .get(`/api/importations/${importationId}`)
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    UPLOAD_FILE: ({ commit }, formData) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .post('/api/importations', formData, {
            headers: {
              'Content-type': 'multipart/form-data',
            },
          })
          .then(res => {
            commit('fileUploaded', {
              currentImportationId: res.data.data.importationId,
              currentCsvHeaders: res.data.data.csvHeaders,
            })
            resolve(res)
          })
          .catch(err => reject(err))
      }),
    START_IMPORTATION: (state, { importationId, paramImportation }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .post(`/api/importations/${importationId}`, paramImportation)
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
  },
}
