/* eslint-disable no-param-reassign */
/* eslint-disable no-console */
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr'

export default {
  install(Vue) {
    // use a new Vue instance as the interface for Vue components to receive/send SignalR events
    // this way every component can listen to events or send new events using this.$punchHub
    const punchHub = new Vue()
    Vue.prototype.$punchHub = punchHub

    // Provide methods to connect/disconnect from the SignalR hub
    let connection = null
    let startedPromise = null
    let manuallyClosed = false

    punchHub.isStarted = () => !!startedPromise
    punchHub.start = accessToken => {
      if (connection) {
        return Promise.reject(new Error('[PunchHub] SignalR is already started or starting'))
      }

      connection = new HubConnectionBuilder()
        .withUrl(`${process.env.VUE_APP_API_ENDPOINT}/api/hubs/punches`, {
          accessTokenFactory: () => accessToken,
        })
        .configureLogging(LogLevel.Information)
        .build()

      // Forward hub events through the event, so we can listen for them in the Vue components
      // connection.on('EventName', arg1 => {
      //   punchHub.$emit('event-name', arg1)
      // })

      // You need to call connection.start() to establish the connection but
      // the client wont handle reconnecting for you!
      // Docs recommend listening onclose and handling it there.
      // This is the simplest of the strategies
      function start() {
        startedPromise = connection.start().catch(err => {
          console.error('[ActionHub] Failed to connect with hub', err)
          return new Promise(
            (resolve, reject) => setTimeout(() => start().then(resolve).catch(reject), 5000),
            // eslint-disable-next-line function-paren-newline
          )
        })
        return startedPromise
      }
      connection.onclose(() => {
        if (!manuallyClosed) start()
      })

      // Start everything
      manuallyClosed = false
      return start()
    }
    punchHub.stop = () => {
      if (!startedPromise) {
        return Promise.reject(new Error('[PunchHub] SignalR not started'))
      }

      manuallyClosed = true
      return startedPromise
        .then(() => connection.stop())
        .then(() => {
          startedPromise = null
        })
        .catch(console.error)
    }

    // Provide methods for components to send messages back to server
    // Make sure no invocation happens until the connection is established
    punchHub.worksheetOpened = campaignLnkProspectId => {
      if (!startedPromise) {
        return Promise.reject(new Error('[PunchHub] SignalR not started'))
      }

      return startedPromise
        .then(
          () => connection.invoke('WorksheetOpened', campaignLnkProspectId),
          // eslint-disable-next-line function-paren-newline
        )
        .catch(console.error)
    }
    punchHub.worksheetClosed = campaignLnkProspectId => {
      if (!startedPromise) {
        return Promise.reject(new Error('[PunchHub] SignalR not started'))
      }

      return startedPromise
        .then(() => connection.invoke('WorksheetClosed', campaignLnkProspectId))
        .catch(console.error)
    }
  },
}
