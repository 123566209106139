/* eslint-disable import/first */
import Vue from 'vue'
import { BVConfigPlugin, ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueLodash from 'vue-lodash'
import lodash from 'lodash'
import moment from 'moment'
import momentDurationFormatSetup from 'moment-duration-format'
import VueCompositionAPI from '@vue/composition-api'
import L from 'leaflet'
import i18n from '@/libs/i18n'
import punchHub from '@/libs/hubs/punch-hub'
import actionHub from '@/libs/hubs/action-hub'
import notificationHub from '@/libs/hubs/notification-hub'
import posthogPlugin from '@/libs/posthog'
import router from './router'
import store from './store'
import App from './App.vue'

Vue.use(BVConfigPlugin, {
  breakpoints: ['xs', 'sm', 'lg', 'xl', 'xxl'],
})

// Global Components
import './global-components'

// import french language for moment
import 'moment/locale/fr'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'
import '@/libs/log-rocket'
import '@/libs/vue-azure-uploader'
import '@/libs/shortkey'
import '@/libs/idle-vue'

// Axios Mock Adapter
import '@/@fake-db/db'

// Custom Library CSS files
import 'leaflet/dist/leaflet.css'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// Lodash
Vue.use(VueLodash, { lodash })

// Posthog
Vue.use(posthogPlugin)

// Moment
momentDurationFormatSetup(moment)
Vue.prototype.moment = moment

// Hubs Boostrap
Vue.use(notificationHub)
Vue.use(punchHub)
Vue.use(actionHub)

// Expose store in the Vue prototype
Vue.prototype.$store = store

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

// Leaflet icon issue
/* eslint-disable */
delete L.Icon.Default.prototype._getIconUrl
L.Icon.Default.imagePath = ''
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})
/* eslint-enable */

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
