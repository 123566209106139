import Vue from 'vue'
import moment from 'moment'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    /**
     * Create a new communication history
     * @param campaignLnkProspectId The unique ID between a campaign and a prospect
     * @param commHistory The new communication to create with the API
     * @returns The newly created communication
     */
    CREATE_HISTORY_COMMUNICATION: (state, { campaignLnkProspectId, commHistory }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .post(`/api/worksheets/${campaignLnkProspectId}/communication-history`, {
            message: commHistory.message,
            statusId: commHistory.status ? commHistory.status.id : null,
            extracted: commHistory.extracted,
            followUpDate: commHistory.followUpDate,
            // Here we pass it through moment because it has a time attached to it contrary
            // to the followUpDate which is only a date
            reminderDate: moment(commHistory.reminderDate).format(),
          })
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    /**
     * Update a communication history
     * @param campaignLnkProspectHistoricId The ID of the updated communication history
     * @param campaignLnkProspectId The unique ID between a campaign and a prospect
     * @param commHistory An updated version of the contact to send to the API
     * @returns The updated contact
     */
    UPDATE_HISTORY_COMMUNICATION: (
      state,
      { campaignLnkProspectId, campaignLnkProspectHistoricId, commHistory },
    ) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .put(
            `/api/worksheets/${campaignLnkProspectId}/communication-history/${campaignLnkProspectHistoricId}`,
            {
              statusId: commHistory.status ? commHistory.status.id : commHistory.statusId,
              message: commHistory.message,
              extracted: commHistory.extracted,
              followUpDate: commHistory.followUpDate,
              reminderDate: moment(commHistory.reminderDate).format(),
            },
          )
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    /**
     * Delete a communication history
     * @param campaignLnkProspectHistoricId The ID of the communication history
     * @param campaignLnkProspectId The unique ID between a campaign and a prospect
     * @returns 204 - No Content
     */
    DELETE_HISTORY_COMMUNICATION: (
      state,
      { campaignLnkProspectId, campaignLnkProspectHistoricId },
    ) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .delete(
            `/api/worksheets/${campaignLnkProspectId}/communication-history/${campaignLnkProspectHistoricId}`,
          )
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
  },
}
