import Vue from 'vue'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    GET_STATISTICS: (state, { params, from, to, campaignIds, agentIds, forceFetch }) =>
      new Promise((resolve, reject) => {
        const request = {
          pageNumber: params.pageNumber,
          pageSize: params.pageSize,
          from,
          to,
          campaignIds,
          agentIds,
          forceFetch,
        }

        Vue.prototype.$http
          .get('/api/campaigns/statistics', {
            params: request,
            paramsSerializer: { indexes: true }
          })
          .then(res => resolve(res))
          .catch(err => reject(err))
      })
  }
}
