import mock from '@/@fake-db/mock'
/* eslint-disable global-require */
const data = {
  tableBasic: [
    {
      id: 1,
      avatar: require('@/assets/images/avatars/10-small.png'),
      fullName: "Korrie O'Crevy",
      email: 'kocrevy0@thetimes.co.uk',
      startDate: '09/23/2016',
      salary: '$23896.35',
      status: 'Professional',
    },
    {
      id: 2,
      avatar: require('@/assets/images/avatars/1-small.png'),
      fullName: 'Bailie Coulman',
      email: 'bcoulman1@yolasite.com',
      startDate: '05/20/2018',
      salary: '$13633.69',
      status: 'Resigned',
    },
    {
      id: 3,
      avatar: require('@/assets/images/avatars/9-small.png'),
      fullName: 'Stella Ganderton',
      email: 'sganderton2@tuttocitta.it',
      startDate: '03/24/2018',
      salary: '$13076.28',
      status: 'Professional',
    },
    {
      id: 4,
      avatar: require('@/assets/images/avatars/3-small.png'),
      fullName: 'Dorolice Crossman',
      email: 'dcrossman3@google.co.jp',
      startDate: '12/03/2017',
      salary: '$12336.17',
      status: 'Applied',
    },
    {
      id: 5,
      avatar: require('@/assets/images/avatars/4-small.png'),
      fullName: 'Harmonia Nisius',
      email: 'hnisius4@gnu.org',
      startDate: '08/25/2017',
      salary: '$10909.52',
      status: 'Professional',
    },
    {
      id: 6,
      avatar: require('@/assets/images/avatars/5-small.png'),
      fullName: 'Genevra Honeywood',
      email: 'ghoneywood5@narod.ru',
      startDate: '06/01/2017',
      salary: '$17803.8',
      status: 'Current',
    },
    {
      id: 7,
      avatar: require('@/assets/images/avatars/7-small.png'),
      fullName: 'Eileen Diehn',
      email: 'ediehn6@163.com',
      startDate: '10/15/2017',
      salary: '$18991.67',
      status: 'Professional',
    },
    {
      id: 8,
      avatar: require('@/assets/images/avatars/9-small.png'),
      fullName: 'Richardo Aldren',
      email: 'raldren7@mtv.com',
      startDate: '11/05/2016',
      salary: '$19230.13',
      status: 'Rejected',
    },
    {
      id: 9,
      avatar: require('@/assets/images/avatars/2-small.png'),
      fullName: 'Allyson Moakler',
      email: 'amoakler8@shareasale.com',
      startDate: '12/29/2018',
      salary: '$11677.32',
      status: 'Applied',
    },
    {
      id: 10,
      avatar: require('@/assets/images/avatars/6-small.png'),
      fullName: 'Merline Penhalewick',
      email: 'mpenhalewick9@php.net',
      startDate: '04/19/2019',
      salary: '$15939.52',
      status: 'Professional',
    },
  ],

  tableRowGrp: [
    {
      mode: 'span',
      label: 'Accountant',
      html: false,
      children: [
        {
          id: 1,
          avatar: require('@/assets/images/avatars/10-small.png'),
          fullName: "Korrie O'Crevy",
          email: 'kocrevy0@thetimes.co.uk',
          startDate: '09/23/2016',
          salary: '$23896.35',
          experience: '1 Year',
          status: 'Professional',
        },
      ],
    },
    {
      mode: 'span',
      label: 'Actuary',
      children: [
        {
          id: 2,
          avatar: require('@/assets/images/avatars/1-small.png'),
          fullName: 'Bailie Coulman',
          email: 'bcoulman1@yolasite.com',
          startDate: '05/20/2018',
          salary: '$13633.69',
          status: 'Current',
        },
      ],
    },
    {
      mode: 'span',
      label: 'Administrative Assistan',
      children: [
        {
          id: 3,
          avatar: require('@/assets/images/avatars/9-small.png'),
          fullName: 'Stella Ganderton',
          email: 'sganderton2@tuttocitta.it',
          startDate: '03/24/2018',
          salary: '$13076.28',
          status: 'Applied',
        },
        {
          id: 4,
          avatar: require('@/assets/images/avatars/3-small.png'),
          fullName: 'Dorolice Crossman',
          email: 'dcrossman3@google.co.jp',
          startDate: '12/03/2017',
          salary: '$12336.17',
          status: 'Professional',
        },
        {
          id: 5,
          avatar: require('@/assets/images/avatars/4-small.png'),
          fullName: 'Harmonia Nisius',
          email: 'hnisius4@gnu.org',
          startDate: '08/25/2017',
          salary: '$10909.52',
          status: 'Resigned',
        },
      ],
    },
    {
      mode: 'span',
      label: 'Analog Circuit Design manager',
      children: [
        {
          id: 6,
          avatar: require('@/assets/images/avatars/5-small.png'),
          fullName: 'Genevra Honeywood',
          email: 'ghoneywood5@narod.ru',
          startDate: '06/01/2017',
          salary: '$17803.8',
          experience: '1 Year',
          status: 'Current',
        },
        {
          id: 7,
          avatar: require('@/assets/images/avatars/7-small.png'),
          fullName: 'Eileen Diehn',
          email: 'ediehn6@163.com',
          startDate: '10/15/2017',
          salary: '$18991.67',
          status: 'Professional',
        },
      ],
    },
    {
      mode: 'span',
      label: 'Analyst Programmer',
      children: [
        {
          id: 8,
          avatar: require('@/assets/images/avatars/9-small.png'),
          fullName: 'Richardo Aldren',
          email: 'raldren7@mtv.com',
          startDate: '11/05/2016',
          salary: '$19230.13',
          status: 'Rejected',
        },
        {
          id: 9,
          avatar: require('@/assets/images/avatars/2-small.png'),
          fullName: 'Allyson Moakler',
          email: 'amoakler8@shareasale.com',
          startDate: '12/29/2018',
          salary: '$11677.32',
          status: 'Applied',
        },
        {
          id: 10,
          avatar: require('@/assets/images/avatars/6-small.png'),
          fullName: 'Merline Penhalewick',
          email: 'mpenhalewick9@php.net',
          startDate: '04/19/2019',
          salary: '$15939.52',
          status: 'Professional',
        },
      ],
    },
  ],

  tableAdvancedSearch: [
    {
      id: 1,
      avatar: require('@/assets/images/avatars/10-small.png'),
      fullName: "Korrie O'Crevy",
      post: 'Nuclear Power Engineer',
      email: 'kocrevy0@thetimes.co.uk',
      city: 'Krasnosilka',
      startDate: '09/23/2016',
      salary: '$23896.35',
    },
    {
      id: 2,
      avatar: require('@/assets/images/avatars/1-small.png'),
      fullName: 'Bailie Coulman',
      post: 'VP Quality Control',
      email: 'bcoulman1@yolasite.com',
      city: 'Hinigaran',
      startDate: '05/20/2018',
      salary: '$13633.69',
    },
    {
      id: 3,
      avatar: require('@/assets/images/avatars/9-small.png'),
      fullName: 'Stella Ganderton',
      post: 'Operator',
      email: 'sganderton2@tuttocitta.it',
      city: 'Golcowa',
      startDate: '03/24/2018',
      salary: '$13076.28',
    },
    {
      id: 4,
      avatar: require('@/assets/images/avatars/3-small.png'),
      fullName: 'Dorolice Crossman',
      post: 'Cost Accountant',
      email: 'dcrossman3@google.co.jp',
      city: 'Paquera',
      startDate: '12/03/2017',
      salary: '$12336.17',
    },
    {
      id: 5,
      avatar: require('@/assets/images/avatars/4-small.png'),
      fullName: 'Harmonia Nisius',
      post: 'Senior Cost Accountant',
      email: 'hnisius4@gnu.org',
      city: 'Lucan',
      startDate: '08/25/2017',
      salary: '$10909.52',
    },
    {
      id: 6,
      avatar: require('@/assets/images/avatars/5-small.png'),
      fullName: 'Genevra Honeywood',
      post: 'Geologist',
      email: 'ghoneywood5@narod.ru',
      city: 'Maofan',
      startDate: '06/01/2017',
      salary: '$17803.8',
    },
    {
      id: 7,
      avatar: require('@/assets/images/avatars/7-small.png'),
      fullName: 'Eileen Diehn',
      post: 'Environmental Specialist',
      email: 'ediehn6@163.com',
      city: 'Lampuyang',
      startDate: '10/15/2017',
      salary: '$18991.67',
    },
    {
      id: 8,
      avatar: require('@/assets/images/avatars/9-small.png'),
      fullName: 'Richardo Aldren',
      post: 'Senior Sales Associate',
      email: 'raldren7@mtv.com',
      city: 'Skoghall',
      startDate: '11/05/2016',
      salary: '$19230.13',
    },
    {
      id: 9,
      avatar: require('@/assets/images/avatars/2-small.png'),
      fullName: 'Allyson Moakler',
      post: 'Safety Technician',
      email: 'amoakler8@shareasale.com',
      city: 'Mogilany',
      startDate: '12/29/2018',
      salary: '$11677.32',
    },
    {
      id: 10,
      avatar: require('@/assets/images/avatars/6-small.png'),
      fullName: 'Merline Penhalewick',
      post: 'Junior Executive',
      email: 'mpenhalewick9@php.net',
      city: 'Kanuma',
      startDate: '04/19/2019',
      salary: '$15939.52',
    },
  ],
  tableSsr: [
    {
      id: 1,
      avatar: require('@/assets/images/avatars/10-small.png'),
      fullName: "Korrie O'Crevy",
      email: 'kocrevy0@thetimes.co.uk',
      startDate: '09/23/2016',
      salary: '$23896.35',
      status: 'Professional',
    },
    {
      id: 2,
      avatar: require('@/assets/images/avatars/1-small.png'),
      fullName: 'Bailie Coulman',
      email: 'bcoulman1@yolasite.com',
      startDate: '05/20/2018',
      salary: '$13633.69',
      status: 'Resigned',
    },
    {
      id: 3,
      avatar: require('@/assets/images/avatars/9-small.png'),
      fullName: 'Stella Ganderton',
      email: 'sganderton2@tuttocitta.it',
      startDate: '03/24/2018',
      salary: '$13076.28',
      status: 'Professional',
    },
    {
      id: 4,
      avatar: require('@/assets/images/avatars/3-small.png'),
      fullName: 'Dorolice Crossman',
      email: 'dcrossman3@google.co.jp',
      startDate: '12/03/2017',
      salary: '$12336.17',
      status: 'Applied',
    },
    {
      id: 5,
      avatar: require('@/assets/images/avatars/4-small.png'),
      fullName: 'Harmonia Nisius',
      email: 'hnisius4@gnu.org',
      startDate: '08/25/2017',
      salary: '$10909.52',
      status: 'Professional',
    },
  ],
  tableFollowUp: [
    {
      rowNumber: 1,
      campaignLnkProspectId: 354668,
      status: 'Rendez-vous obtenu',
      statusId: 4,
      followUpDate: '2025-01-12',
      campaign: 'Académie Sylbert 1',
      prospect: 'France Delices & Quali Dessert',
      agentName: 'Yann, Hugues',
      lastUpdatedOn: '2018-07-12',
      city: 'Montréal',
      province: 'QC',
      meetingDate: '2018-10-26',
      active: true,
      reminderDate: '',
    },
    {
      rowNumber: 2,
      campaignLnkProspectId: 354975,
      status: 'Rendez-vous Confirmés',
      statusId: 6,
      followUpDate: '2021-03-18',
      campaign: 'Académie Sylbert 1',
      prospect: 'Securite Polygon Inc',
      agentName: 'Yann, Hugues',
      lastUpdatedOn: '2020-08-14',
      city: 'Lachine',
      province: 'QC',
      meetingDate: '',
      active: true,
      reminderDate: '',
    },
    {
      rowNumber: 3,
      campaignLnkProspectId: 389411,
      status: 'Suivi',
      statusId: 7,
      followUpDate: '2021-03-19',
      campaign: 'Académie Sylbert 1',
      prospect: 'Autorité Des Marchés Financiers',
      agentName: 'Yann, Hugues',
      lastUpdatedOn: '2018-07-12',
      city: 'Montréal',
      province: 'QC',
      meetingDate: '',
      active: true,
      reminderDate: '',
    },
    {
      rowNumber: 4,
      campaignLnkProspectId: 389418,
      status: 'Rendez-vous obtenu',
      statusId: 4,
      followUpDate: '2021-03-20',
      campaign: 'Académie Sylbert 1',
      prospect: 'Entreprises Marchand Ltee',
      agentName: 'Yann, Hugues',
      lastUpdatedOn: '2018-07-12',
      city: 'Sainte-Catherine',
      province: 'QC',
      meetingDate: '',
      active: true,
      reminderDate: '',
    },
    {
      rowNumber: 5,
      campaignLnkProspectId: 951772,
      status: 'Rendez-vous obtenu',
      statusId: 4,
      followUpDate: '2021-03-14',
      campaign: 'Épisode 2018',
      prospect: '5N Plus Inc',
      agentName: 'Yann, Hugues',
      lastUpdatedOn: '',
      city: 'Saint-Laurent',
      province: 'QC',
      meetingDate: '2019-03-13',
      active: true,
      reminderDate: '',
    },
  ],
  tableCampaigns: {
    pageNumber: 1,
    pageSize: 2,
    totalPages: 1,
    totalRecords: 2,
    firstPage: '/api/prospects?pageNumber=1&pageSize=35',
    lastPage: '/api/prospects?pageNumber=2410&pageSize=35',
    nextPage: '/api/prospects?pageNumber=2&pageSize=35',
    previousPage: null,
    data: [
      {
        campaignLnkProspectId: 635088,
        status: 'Non rejoint',
        statusId: 3,
        gridFollowUpDate: null,
        gridCampaign: 'MASTER QC',
        username: '',
        usernameId: -1,
        gridLastUpdatedOn: '',
        gridMeetingDate: null,
        active: true,
        confirmedBy: '',
        confirmedById: -1,
        campaignId: 6300,
        value: 0,
        custom1: '',
        custom2: '',
        privateCampaign: false,
        prospectId: 318502,
        groupInsuranceRenewal: '',
        gridProspect: 'Ferme Louis Voyer',
        phone: '(418) 498-2307',
        postalCode: 'G0L 1W0',
        city: 'Mont-Carmel',
        region: '',
        province: 'QC',
        sic: '',
        fic: '',
        suite: '',
        damageInsuranceRenewal: '',
        endOfFiscalYear: '',
        webSite: '',
        turnover: 0.0,
        numberOfEmployees: 0,
        lastModifiedEmployeeNumber: null,
      },
      {
        campaignLnkProspectId: 635088,
        status: 'Rendez-vous obtenus',
        statusId: 4,
        gridFollowUpDate: null,
        gridCampaign: 'MASTER QC',
        username: '',
        usernameId: -1,
        gridLastUpdatedOn: '2018-07-09T09:52:53.897',
        gridMeetingDate: null,
        active: true,
        confirmedBy: '',
        confirmedById: -1,
        campaignId: 6300,
        value: 0,
        custom1: '',
        custom2: '',
        privateCampaign: false,
        prospectId: 318502,
        groupInsuranceRenewal: '',
        gridProspect: 'Agence Artistique Francois Legault',
        phone: '(418) 498-2307',
        postalCode: 'G0L 1W0',
        city: 'Montréal',
        region: '',
        province: 'QC',
        sic: 'Business consulting nec nsk',
        fic: 'Bureaux - Services Conseils',
        suite: '',
        damageInsuranceRenewal: '',
        endOfFiscalYear: '',
        webSite: 'https://google.com',
        turnover: 0.0,
        numberOfEmployees: 0,
        lastModifiedEmployeeNumber: null,
      },
    ],
  },
  /* eslint-disable global-require */
}

mock.onGet('/good-table/basic').reply(() => [200, data.tableBasic])
mock.onGet('/good-table/row-group').reply(() => [200, data.tableRowGrp])
mock
  .onGet('/good-table/advanced-search')
  .reply(() => [200, data.tableAdvancedSearch])
mock.onGet('/good-table/table_ssr').reply(() => [200, data.tableSsr])
mock.onGet('/good-table/follow-up').reply(() => [200, data.tableFollowUp])
mock.onGet('/good-table/campaigns').reply(() => [200, data.tableCampaigns])
