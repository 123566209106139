import Vue from 'vue'
import qs from 'qs'

export default {
  namespaced: true,
  state: {
    worksheetsStatsFilters: null,
  },
  getters: {},
  mutations: {
    setWorksheetsStatsFilters(state, { filters }) {
      state.worksheetsStatsFilters = {
        ...state.worksheetsStatsFilters,
        ...filters,
      } // merge filters
    },
  },
  actions: {
    GET_RDV_FOR_AGENT: (state, { userId, startDate, endDate }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .get(`/api/statistics/agents/${userId}/rdv`, { params: { startDate, endDate } })
          .then(res => {
            resolve(res)
          })
          .catch(err => {
            reject(err)
          })
      }),
    GET_STAT_FOR_AGENT: (state, { userId, startDate, endDate }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .get(`/api/statistics/agents/${userId}`, { params: { startDate, endDate } })
          .then(res => {
            resolve(res)
          })
          .catch(err => {
            reject(err)
          })
      }),
    GET_STAT_GLOBAL_AGENTS: (state, { params }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .get('/api/statistics/agents', { params })
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    GET_WORKSHEETS_STATISTICS: (state, { params }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .get('/api/worksheets/statistics', {
            params,
            paramsSerializer: toSerializeParams =>
              // So we can pass the roleIds (array) as a query param
              qs.stringify(toSerializeParams),
          })
          .then(res => {
            resolve(res)
          })
          .catch(err => {
            reject(err)
          })
      }),
  },
}
