<template>
  <div
    id="app"
    class="h-100"
    :class="[skinClasses]"
  >
    <component :is="layout">
      <router-view />
    </component>

    <scroll-to-top v-if="enableScrollToTop" />
    <idle-modal />
  </div>
</template>

<script>
import ScrollToTop from '@core/components/scroll-to-top/ScrollToTop.vue'
import IdleModal from '@core/components/idle-modal/IdleModal.vue'
import { BSpinner } from 'bootstrap-vue'
import NotificationHubBoostrap from '@/mixins/NotificationHubBoostrap.vue'

// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig'
import { localize } from 'vee-validate'
// import { provideToast } from 'vue-toastification/composition'
import { loadLang } from '@/@core/libs/i18n/utils'
import { watch } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'

import { isUserLoggedIn, getRole } from '@/auth/utils'

import { useWindowSize, useCssVar } from '@vueuse/core'

import store from '@/store'

const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
const LayoutHorizontal = () => import('@/layouts/horizontal/LayoutHorizontal.vue')
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')

export default {
  components: {
    // Layouts
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,

    ScrollToTop,
    IdleModal,
    // eslint-disable-next-line
    BSpinner,
  },
  mixins: [NotificationHubBoostrap],
  setup() {
    const { skin, skinClasses } = useAppConfig()
    const { enableScrollToTop } = $themeConfig.layout

    // If skin is dark when initialized => Add class to body
    if (skin.value === 'dark') document.body.classList.add('dark-layout')

    // Set Window Width in store
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
    const { width: windowWidth } = useWindowSize()
    watch(windowWidth, val => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val)
    })

    // Get the saved locale
    const locale = loadLang()
    if (locale) {
      localize(locale)
    } else {
      localize('fr')
    }

    return {
      skinClasses,
      enableScrollToTop,
    }
  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    layout() {
      if (this.$route.meta.layout === 'full') return 'layout-full'
      return `layout-${this.contentLayoutType}`
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type
    },
  },
  beforeCreate() {
    // Set colors in theme
    const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim()
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(
        useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2),
      )
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')
  },
  created() {
    if (isUserLoggedIn()) {
      // From mixin NotificationHubBoostrap, start the notification hub
      this.buildAndStartNotificationHub()
    }

    // Reset the session tabId if it's a dupplicate tab
    const navigationType = window.performance.getEntriesByType('navigation')[0].type

    // Duplicate tab detection
    // Normally, 'back_forward' is the type of navigation when user
    // click on back or forward button, since we check only on created
    // we can assume that the user is trying to open (duplicate) a new tab
    if (navigationType === 'back_forward') {
      const pageTabId = Math.random().toString(36).substr(2, 9)
      sessionStorage.setItem('tabId', pageTabId)
    }

    // Count tabs open and save in local storage (shared between tabs)
    this.addOpenTab()

    // Listen for message from other tabs
    const tabsChannel = new BroadcastChannel('idleTabsChannel')
    tabsChannel.onmessage = message => {
      switch (message.data) {
        case 'allTabsIdle':
          if (isUserLoggedIn() && getRole() === 'agent') {
            this.$bvModal.show('idle-modal')
          }
          break
        case 'sessionExtended':
          this.$bvModal.hide('idle-modal')
          break
        default:
          break
      }
    }

    // Remove tab count when tab is closed
    window.onunload = () => {
      tabsChannel.close()
      this.removeOpenTab()
    }
  },
  mounted() {
    if (
      window.matchMedia &&
      window.matchMedia('(prefers-color-scheme: dark)').matches &&
      !localStorage.getItem('vuexy-skin')
    ) {
      this.$store.commit('appConfig/UPDATE_SKIN', 'dark')
    }

    if (isUserLoggedIn()) {
      const userData = JSON.parse(localStorage.getItem('userData'))

      this.$logRocket.identify(userData.userId, {
        name: `${userData.firstName} ${userData.lastName}`,
        role: userData.role,
        email: userData.userMail,
      })

      this.$posthog.identify(userData.userId, {
        name: `${userData.firstName} ${userData.lastName}`,
        username: this.username,
        email: userData.userMail,
        role: userData.role,
      })
    }
  },
  methods: {
    getOrSetTabID() {
      let pageTabId = sessionStorage.getItem('tabId')

      // If tabId is not set, set it
      if (pageTabId == null) {
        pageTabId = Math.random().toString(36).substr(2, 9)
        sessionStorage.setItem('tabId', pageTabId)
      }

      return pageTabId
    },
    addOpenTab() {
      const tabsItem = localStorage.getItem('activeTabs')
      const tabs = tabsItem ? JSON.parse(tabsItem) : []

      const tabId = this.getOrSetTabID()

      if (tabs == null) {
        tabs.push(tabId)

        localStorage.setItem('activeTabs', JSON.stringify(tabs))
      } else {
        if (!tabs.includes(tabId)) {
          tabs.push(tabId)
        }

        localStorage.setItem('activeTabs', JSON.stringify(tabs))
      }
    },
    removeOpenTab() {
      const tabsItem = localStorage.getItem('activeTabs')
      const tabs = tabsItem ? JSON.parse(tabsItem) : []

      const tabId = this.getOrSetTabID()

      if (tabs.includes(tabId)) {
        tabs.splice(tabs.indexOf(tabId), 1)
      }

      localStorage.setItem('activeTabs', JSON.stringify(tabs))

      // If no tabs are open, send message to other tabs
      if (tabs.length === 0) {
        const tabChannels = new BroadcastChannel('idleTabsChannel')
        tabChannels.postMessage('allTabsIdle')
        tabChannels.close()
      }
    },
  },
  onIdle() {
    this.$posthog.capture('Idle')
    this.$logRocket.track('Idle')

    this.removeOpenTab()
  },
  onActive() {
    this.$posthog.capture('Active')
    this.$logRocket.track('Active')

    this.addOpenTab()
  },
}
</script>
