import Vue from 'vue'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    FETCH_SCHEDULES: (state, { startDate }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .get(`/api/employees-schedules/week?startDate=${startDate}`)
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    FETCH_EMPLOYEE_SCHEDULE: (state, { startDate, userId }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .get(`/api/employees-schedules/employee-week?startDate=${startDate}&userId=${userId}`)
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    UPDATE_WEEK_SCHEDULE: (state, { weekSchedule, startDate }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .post(
            `/api/employees-schedules/week?startDate=${startDate}`,
            weekSchedule,
          )
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    UPDATE_EMPLOYEE_HOURS: (state, { schedules, userId }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .post(`/api/employees-schedules/employee-hours?userId=${userId}`, schedules)
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
  },
}
