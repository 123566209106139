import Vue from 'vue'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    PUNCH_IN: () => new Promise((resolve, reject) => {
      Vue.prototype.$http.post('/api/punch/employee/in')
        .then(res => resolve(res))
        .catch(err => reject(err))
    }),
    PUNCH_OUT: (state, data) => new Promise((resolve, reject) => {
      Vue.prototype.$http.post('/api/punch/employee/out', data)
        .then(res => resolve(res))
        .catch(err => reject(err))
    }),
    EDIT_LAST_PUNCH_OUT: (state, data) => new Promise((resolve, reject) => {
      Vue.prototype.$http.post('/api/punch/employee/out/edit-last', data)
        .then(res => resolve(res))
        .catch(err => reject(err))
    }),
    GET_CURRENT: () => new Promise((resolve, reject) => {
      Vue.prototype.$http.get('/api/punch/employee/current')
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    }),
  },
}
