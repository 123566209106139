import Vue from 'vue'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    GET_LINK_CAMPAIGNS_FROM_CAMPAIGNID: (state, { campaignId }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .get(`/api/lnkcampaigns/${campaignId}`)
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    GET_LINK_CAMPAIGNS: () =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .get('/api/lnkcampaigns')
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    CREATE_LINK_CAMPAIGNS: (
      state,
      { campaignId, campaignLinked, linkCalendar, linkCampaignMeetings, linkTechnicalSheet },
    ) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .post(`/api/lnkcampaigns/${campaignId}`, {
            campaignLinked,
            linkCalendar,
            linkCampaignMeetings,
            linkTechnicalSheet,
          })
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    UPDATE_LINK_CAMPAIGNS: (
      state,
      {
        campaignId,
        campaignLinked,
        campaignLnkCampaignId,
        linkCalendar,
        linkCampaignMeetings,
        linkTechnicalSheet,
      },
    ) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .put(`/api/lnkcampaigns/${campaignId}/${campaignLnkCampaignId}`, {
            campaignLinked,
            campaignLnkCampaignId,
            linkCalendar,
            linkCampaignMeetings,
            linkTechnicalSheet,
          })
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
  },
}
