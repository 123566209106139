import Vue from 'vue'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    GET_ALL: (state, { pageNumber, pageSize }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .get(`/api/files?pageNumber=${pageNumber}&pageSize=${pageSize}`)
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    GET_FILE_UPLOAD_URI: (state, { fileName }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .get(`/api/files/upload-uri/${fileName}`)
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    GET_FILE_DOWNLOAD_URI: (state, { fileName }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .get(`/api/files/download-uri/${fileName}`)
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
  },
}
