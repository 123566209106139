import Vue from 'vue'
import utils from '@/libs/utils'
import qs from 'qs'

export default {
  namespaced: true,
  state: {
    pageNumber: 0,
    pageSize: 0,
    totalPages: 0,
    totalRecords: 0,
    campaignLnkProspects: [],
    currentCampaignLnkProspectIndexSelected: -1,
    areFiltersLoading: false,
    campaignFilters: {},
    campaignFiltersOptions: {},
  },
  getters: {
    currentSelectedClnkProspectId: state =>
      state.campaignLnkProspects[state.currentCampaignLnkProspectIndexSelected]
        .campaignLnkProspectId,
    getSanitizedFilters: state => {
      const filteredParam = {
        pageNumber: state.pageNumber,
        pageSize: state.pageLength,
        startingDate: state.campaignFilters.fromDate,
        endingDate: state.campaignFilters.toDate,
        userId: state.campaignFilters.employee
          ? state.campaignFilters.employee.id
          : -1,
        campaignIds:
          !state.campaignFilters.campaign ||
          state.campaignFilters.campaign.map(x => x.id)[0] !== null
            ? state.campaignFilters.campaign.map(x => x.id)
            : -1,
        statusList: utils.getQueryStringFromArrayOfObjectWithKey(
          state.campaignFilters.status,
          'id',
        ),
        cityList: '',
        provinceList: '',
        search: state.campaignFilters.search,
      }
      if (
        filteredParam.pageNumber === '' ||
        filteredParam.pageNumber === undefined
      ) {
        filteredParam.pageNumber = 1
      }
      if (
        filteredParam.startingDate === '' ||
        filteredParam.startingDate === undefined
      ) {
        delete filteredParam.startingDate
      }
      if (
        filteredParam.endingDate === '' ||
        filteredParam.endingDate === undefined
      ) {
        delete filteredParam.endingDate
      }
      if (filteredParam.userId === undefined) {
        filteredParam.userId = -1
      }
      if (filteredParam.campaignIds.length === 0) {
        filteredParam.campaignIds = -1
      }
      if (filteredParam.search === undefined || filteredParam.search === '') {
        delete filteredParam.search
      }
      if (
        filteredParam.statusList === undefined ||
        filteredParam.statusList === ''
      ) {
        filteredParam.statusList = '-1'
      }
      if (
        filteredParam.cityList === undefined ||
        filteredParam.cityList === ''
      ) {
        delete filteredParam.cityList
      }
      if (
        filteredParam.provinceList === undefined ||
        filteredParam.provinceList === ''
      ) {
        delete filteredParam.provinceList
      }

      return { param: filteredParam }
    },
    areCampaignFiltersSaved: state =>
      state.campaignFilters !== undefined &&
      Object.entries(state.campaignFilters).length > 0,
  },
  mutations: {
    setFilterLoading(state) {
      state.areFiltersLoading = true
    },
    storeFilters(state, payload) {
      state.areFiltersLoading = false
      state.campaignFilters = payload
    },
    storeFiltersOptions(state, payload) {
      state.campaignFiltersOptions = payload
    },
    storeCampaignLnkProspects(state, payload) {
      state.pageNumber = payload.pageNumber
      state.pageSize = payload.pageSize
      state.totalPages = payload.totalPages
      state.totalRecords = payload.totalRecords
      state.campaignLnkProspects = payload.campaignLnkProspects
    },
    selectAProspect(state, index) {
      if (index < state.pageSize) {
        state.currentCampaignLnkProspectIndexSelected = index
      } else {
        state.currentCampaignLnkProspectIndexSelected = -1
      }
    },
    resetSelectedProspect(state) {
      state.currentCampaignLnkProspectIndexSelected = -1
    },
  },
  actions: {
    /**
     * Get prospects (follow ups) from API
     * @param {*} None
     * @param { params }
     * @returns Promise of the data loaded
     */
    GET_PROSPECTS: ({ state, commit, getters }, { paramsProspect, force }) =>
      new Promise((resolve, reject) => {
        // If filters are not already cached reject the call
        if (Object.entries(state.campaignFilters).length === 0) {
          return reject(new Error('No filters saved in vuex'))
        }

        const params = getters.getSanitizedFilters.param
        params.pageNumber = paramsProspect.pageNumber
        params.pageSize = paramsProspect.pageSize
        if (force) {
          params.timestamp = new Date().getTime()
        }

        return Vue.prototype.$http
          .get('/api/followups', {
            params,
            paramsSerializer: toSerializeParams =>
              // So we can pass the campaignIds (array) as a query param
              qs.stringify(toSerializeParams),
          })
          .then(res => {
            commit('storeCampaignLnkProspects', {
              pageNumber: res.data.pageNumber,
              pageSize: res.data.pageSize,
              totalRecords: res.data.totalRecords,
              totalPages: res.data.totalPages,
              campaignLnkProspects: res.data.data,
            })
            return resolve(res)
          })
          .catch(err => {
            commit('prospects/storeFilters', {})
            commit('storeCampaignLnkProspects', {
              pageNumber: -1,
              pageSize: 0,
              totalPages: 0,
              campaignLnkProspects: [],
            })
            return reject(err)
          })
      }),
    SELECT_NEXT_PROSPECT: ({ state, getters, commit, dispatch }) =>
      new Promise((resolve, reject) => {
        if (
          state.currentCampaignLnkProspectIndexSelected ===
            state.pageSize - 1 &&
          state.pageNumber < state.totalPages
        ) {
          // If need next page
          dispatch('GET_PROSPECTS', {
            paramsProspect: { pageNumber: state.pageNumber + 1, pageSize: 35 },
          })
            .then(() => {
              commit('selectAProspect', 0)
              resolve(getters.currentSelectedClnkProspectId)
            })
            .catch(err => reject(err))
        } else if (
          state.currentCampaignLnkProspectIndexSelected ===
            state.pageSize - 1 &&
          state.pageNumber === state.totalPages
        ) {
          // If max prospect reached
          dispatch('GET_PROSPECTS', {
            paramsProspect: { pageNumber: 1, pageSize: 35 },
          })
            .then(() => {
              commit('selectAProspect', 0)
              resolve(getters.currentSelectedClnkProspectId)
            })
            .catch(err => reject(err))
        } else {
          // Select normally
          commit(
            'selectAProspect',
            state.currentCampaignLnkProspectIndexSelected + 1,
          )
          resolve(getters.currentSelectedClnkProspectId)
        }
      }),
    SELECT_PREVIOUS_PROSPECT: ({ state, getters, commit, dispatch }) =>
      new Promise((resolve, reject) => {
        if (
          state.currentCampaignLnkProspectIndexSelected === 0 &&
          state.pageNumber > 1
        ) {
          dispatch('GET_PROSPECTS', {
            paramsProspect: { pageNumber: state.pageNumber - 1, pageSize: 35 },
          })
            .then(() => {
              commit('selectAProspect', state.pageSize - 1)
              resolve(getters.currentSelectedClnkProspectId)
            })
            .catch(err => reject(err))
        } else if (
          state.currentCampaignLnkProspectIndexSelected === 0 &&
          state.pageNumber === 1
        ) {
          // If min prospect reached
          dispatch('GET_PROSPECTS', {
            paramsProspect: { pageNumber: state.totalPages, pageSize: 35 },
          })
            .then(() => {
              commit('selectAProspect', state.pageSize - 1)
              resolve(getters.currentSelectedClnkProspectId)
            })
            .catch(err => reject(err))
        } else {
          // Select normally
          commit(
            'selectAProspect',
            state.currentCampaignLnkProspectIndexSelected - 1,
          )
          resolve(getters.currentSelectedClnkProspectId)
        }
      }),
    PUT_PROSPECT_INFO: ({ commit }, { prospectId, putPayload }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .put(`/api/prospects/${prospectId}`, putPayload)
          .then(res => {
            commit(
              'worksheet/storeProspectInfo',
              {
                prospectInfo: res,
              },
              { root: true },
            )
            resolve(res)
          })
          .catch(err => {
            reject(err)
          })
      }),
    SELECT_A_PROSPECT: ({ commit }, prospectIndex) =>
      new Promise(resolve => {
        commit('selectAProspect', prospectIndex)
        commit('worksheet/setProspectsType', 'followUpProspects', {
          root: true,
        })
        resolve(prospectIndex)
      }),
    RESET_SELECTED_PROSPECT: ({ commit }) =>
      new Promise(resolve => {
        commit('resetSelectedProspect')
        resolve()
      }),
    CLEAR_FILTERS: ({ commit }) => {
      commit('storeFilters', {})
      commit('storeFiltersOptions', {})
    },
    GET_PROSPECTS_IDS: ({ getters }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .post('/api/followups/all-ids', getters.getSanitizedFilters.param)
          .then(res => {
            resolve(res)
          })
          .catch(err => {
            reject(err)
          })
      }),
    UPDATE_FOLLOWUP_DATE: (state, { campaignLnkProspectId, followUpDate }) =>
      new Promise((resolve, reject) => {
        const params = {
          followUpDate:
            followUpDate && followUpDate.trim() !== '' ? followUpDate : null,
        }

        Vue.prototype.$http
          .put(`/api/followups/${campaignLnkProspectId}`, params)
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
  },
}
