import Vue from 'vue'

export default {
  namespaced: true,
  state: {},
  getters: {},
  actions: {
    GET_CONTACT_TITLES: (context, { params }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .get('/api/contacts/titles', { params })
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    CREATE_CONTACT_TITLE: (context, { data }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .post('/api/contacts/titles', data)
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    UPDATE_CONTACT_TITLE: (context, { contactTitleId, data }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .put(`/api/contacts/titles/${contactTitleId}`, data)
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    DELETE_CONTACT_TITLE: (context, { contactTitleId }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .delete(`/api/contacts/titles/${contactTitleId}`)
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
  },
}
