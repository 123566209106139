import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import {
  isUserLoggedIn,
  getUserData,
  getHomeRouteForLoggedInUser,
  closeSession,
} from '@/auth/utils'

import apps from './routes/apps'
import pages from './routes/pages'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'home' } },
    ...apps,
    ...pages,
    {
      path: '*',
      redirect: { name: 'error-404' },
    },
  ],
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) {
      return closeSession(() => {
        next({ name: 'auth-login', query: { redirect: to.fullPath } })
      })
    }

    // If logged in => not authorized, redirect to home page
    const userData = getUserData()
    return next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    if (to.name === 'auth-login' && to.query.redirect) {
      return next(to.query.redirect)
    }

    const userData = getUserData()
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  }

  return next()
})

router.afterEach(() => {
  Vue.prototype.$posthog.capture('$pageview')
})

export default router
