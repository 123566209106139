import Vue from 'vue'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    CHECK_IF_LOCK: (state, { campaignLnkId }) => new Promise((resolve, reject) => {
      if (!campaignLnkId) {
        return reject(new Error('Null parameter passed as campaignLnkId'))
      }
      return Vue.prototype.$http.get(`/api/lock/worksheet/${campaignLnkId}`)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    }),
    LOCK: (state, { campaignLnkId }) => new Promise((resolve, reject) => {
      if (!campaignLnkId) {
        return reject(new Error('Null parameter passed as campaignLnkId'))
      }
      return Vue.prototype.$http.post(`/api/lock/worksheet/${campaignLnkId}/lock`)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    }),
    UNLOCK: (state, { campaignLnkId }) => new Promise((resolve, reject) => {
      if (!campaignLnkId) {
        return reject(new Error('Null parameter passed as campaignLnkId'))
      }
      return Vue.prototype.$http.post(`/api/lock/worksheet/${campaignLnkId}/unlock`)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    }),
  }
}
