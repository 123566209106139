import Vue from 'vue'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    GET_CLIPBOARDS: (state, params) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .get('/api/filters/clipboards', { params })
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    GET_DEFAULT_CLIPBOARDS: (state, params) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .get('/api/default-clipboards', params)
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    CREATE_DEFAULT_CLIPBOARD: (state, { data }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .post('/api/default-clipboards', data)
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    UPDATE_DEFAULT_CLIPBOARD: (state, { clipboardId, data }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .put(`/api/default-clipboards/${clipboardId}`, data)
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    DELETE_DEFAULT_CLIPBOARD: (state, { clipboardId }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .delete(`/api/default-clipboards/${clipboardId}`)
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
  },
}
