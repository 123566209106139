<template>
  <span
    class="iconify-icon"
    @click="clickAction"
  >
    <icon
      :icon="icon"
      v-bind="$attrs"
      :style="{ fontSize: size + 'px' }"
    />
    <span
      v-if="badge"
      :class="['badge badge-up badge-pill', badgeClasses]"
    >
      {{ badge }}
    </span>
  </span>
</template>

<script>
import { Icon } from '@iconify/vue2'

export default {
  name: 'IconifyIcon',
  components: {
    Icon,
  },
  props: {
    icon: {
      required: true,
      type: [String, Object],
    },
    size: {
      type: String,
      default: '14',
    },
    badge: {
      type: [String, Object, Number],
      default: null,
    },
    badgeClasses: {
      type: [String, Object, Array],
      default: 'badge-primary',
    },
    clickAction: {
      type: Function,
      default: () => {},
    },
  },
}
</script>

<style lang="scss">
svg.iconify {
  outline: none;
}

.iconify-icon {
  svg {
    display: inline !important;
    vertical-align: middle !important;
  }
  .badge.badge-up {
    right: 0 !important;
  }
}
</style>
