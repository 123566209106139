<template>
  <b-input-group class="input-group-merge">
    <b-input-group-prepend
      is-text
      @click="$refs.searchInput.focus()"
    >
      <feather-icon icon="SearchIcon" />
    </b-input-group-prepend>
    <b-form-input
      ref="searchInput"
      :value="value"
      :placeholder="$t('common.search-placeholder')"
      :autofocus="autofocus"
      autocomplete="off"
      data-lpignore="true"
      data-form-type="other"
      @input="onInput"
    />
    <b-input-group-append
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      class="reset-search-btn"
      is-text
      @click="onReset"
    >
      <feather-icon icon="XIcon" />
    </b-input-group-append>
  </b-input-group>
</template>

<script>
import { BInputGroup, BInputGroupPrepend, BInputGroupAppend, BFormInput } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { debounce } from 'lodash'

export default {
  name: 'SearchInput',
  components: {
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BFormInput,
  },
  directives: {
    Ripple,
  },
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    // eslint-disable-next-line func-names
    onInput: debounce(function (event) {
      this.$emit('input', event)
    }, 250),
    onReset() {
      this.$emit('input', '')
      this.$refs.searchInput.focus()
    },
  },
}
</script>

<style lang="scss" scoped>
// Color palettes
@import '~@core/scss/base/core/colors/palette-variables.scss';

.reset-search-btn {
  &:hover {
    cursor: pointer;
    svg {
      color: $danger;
      transition: color 0.2s ease;
    }
  }
  svg {
    transition: color 0.2s ease;
  }
}
</style>
