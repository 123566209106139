/* eslint-disable no-param-reassign */
/* eslint-disable no-console */
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr'

export default {
  install(Vue) {
    // use a new Vue instance as the interface for Vue components to receive/send SignalR events
    // this way every component can listen to events or send new events using this.$punchHub
    const actionHub = new Vue()
    Vue.prototype.$actionHub = actionHub

    // Provide methods to connect/disconnect from the SignalR hub
    let connection = null
    let startedPromise = null
    let manuallyClosed = false

    actionHub.isStarted = () => !!startedPromise
    actionHub.connectionId = () => connection?.connectionId || null
    actionHub.start = accessToken => {
      if (connection) {
        return Promise.reject(new Error('[ActionHub] SignalR is already started or starting'))
      }

      connection = new HubConnectionBuilder()
        .withUrl(`${process.env.VUE_APP_API_ENDPOINT}/api/hubs/actions`, {
          accessTokenFactory: () => accessToken,
        })
        .configureLogging(LogLevel.Information)
        .build()

      // Forward hub events through the event, so we can listen for them in the Vue components
      connection.on('refresh-filters', () => {
        actionHub.$emit('refresh-filters')
      })

      // Support Tickets Actions
      connection.on('new-support-ticket-comment', (commenter, comment) => {
        actionHub.$emit('new-support-ticket-comment', { commenter, comment })
      })
      connection.on('SupportTicketStartTyping', (user, content) => {
        actionHub.$emit('support-ticket-start-typing', { user, content })
      })

      connection.on('SupportTicketUpdateTyping', content => {
        actionHub.$emit('support-ticket-update-typing', { content })
      })

      connection.on('SupportTicketStopTyping', (userId, content) => {
        actionHub.$emit('support-ticket-stop-typing', { userId, content })
      })

      // You need to call connection.start() to establish the connection
      // but the client wont handle reconnecting for you!
      // Docs recommend listening onclose and handling it there.
      // This is the simplest of the strategies
      function start() {
        startedPromise = connection.start().catch(err => {
          console.error('[ActionHub] Failed to connect with hub', err)
          return new Promise(
            (resolve, reject) => setTimeout(() => start().then(resolve).catch(reject), 5000),
            // eslint-disable-next-line function-paren-newline
          )
        })
        return startedPromise
      }
      connection.onclose(() => {
        if (!manuallyClosed) start()
      })

      // Start everything
      manuallyClosed = false
      return start()
    }
    actionHub.stop = () => {
      if (!startedPromise) {
        return Promise.reject(new Error('[ActionHub] SignalR not started'))
      }

      manuallyClosed = true
      return startedPromise
        .then(() => connection.stop())
        .then(() => {
          startedPromise = null
        })
        .catch(console.error)
    }

    // Provide methods for components to send messages back to server
    // Make sure no invocation happens until the connection is established

    // Support Tickets Actions
    actionHub.supportTicketOpened = supportTicketId => {
      if (!startedPromise) {
        return Promise.reject(new Error('[ActionHub] SignalR not started'))
      }

      return startedPromise
        .then(
          () => connection.invoke('SupportTicketOpened', parseInt(supportTicketId, 10)),
          // eslint-disable-next-line function-paren-newline
        )
        .catch(console.error)
    }
    actionHub.supportTicketClosed = supportTicketId => {
      if (!startedPromise) {
        return Promise.reject(new Error('[ActionHub] SignalR not started'))
      }

      return startedPromise
        .then(() => connection.invoke('SupportTicketClosed', parseInt(supportTicketId, 10)))
        .catch(console.error)
    }
    actionHub.supportTicketStartTyping = (supportTicketId, content) => {
      if (!startedPromise) {
        return Promise.reject(new Error('[ActionHub] SignalR not started'))
      }

      return startedPromise
        .then(
          () =>
            connection.invoke('SupportTicketStartTyping', parseInt(supportTicketId, 10), content),
          // eslint-disable-next-line function-paren-newline
        )
        .catch(console.error)
    }
    actionHub.supportTicketUpdateTyping = (supportTicketId, content) => {
      if (!startedPromise) {
        return Promise.reject(new Error('[ActionHub] SignalR not started'))
      }

      return startedPromise
        .then(
          () =>
            connection.invoke('SupportTicketUpdateTyping', parseInt(supportTicketId, 10), content),
          // eslint-disable-next-line function-paren-newline
        )
        .catch(console.error)
    }
    actionHub.supportTicketStopTyping = (supportTicketId, content) => {
      if (!startedPromise) {
        return Promise.reject(new Error('[ActionHub] SignalR not started'))
      }

      return startedPromise
        .then(
          () =>
            connection.invoke('SupportTicketStopTyping', parseInt(supportTicketId, 10), content),
          // eslint-disable-next-line function-paren-newline
        )
        .catch(console.error)
    }
  },
}
