import Vue from 'vue'
import qs from 'qs'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    GET_CLIENTS: (state, { params }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .get('/api/filters/clients', { params })
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    GET_CAMPAIGNS: (state, { params }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .get('/api/filters/campaigns', { params })
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    GET_STATUSES: () =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .get('/api/filters/statuses')
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    GET_USERS: (state, params) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .get('/api/filters/users', {
            params: { roleIds: params ? params.roleIds : [] },
            paramsSerializer: toSerializeParams =>
              // So we can pass the roleIds (array) as a query param
              qs.stringify(toSerializeParams),
          })
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    GET_LOCATIONS: (state, { params }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .get('/api/filters/locations', { params })
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    GET_CODES: (state, { params }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .get('/api/filters/codes', { params })
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    GET_CUSTOMS: (state, { params }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .get('/api/filters/customs', { params })
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    GET_PROSPECT_FIELDS: () =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .get('/api/filters/prospectFields')
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
  },
}
