import Vue from 'vue'

// eslint-disable-next-line no-unused-vars
function convertToAPIFormat(
  user,
  generalInformations,
  appointmentInformations,
  script,
  clipboards,
  technicalSheet,
  lnkCampaigns,
) {
  return {
    createdById: user.userId,
    name: generalInformations.campaignName,
    clientName: generalInformations.clientName,
    clientId: generalInformations.clientId,
    contact: generalInformations.principalContact,
    phone: generalInformations.phone,
    email: generalInformations.email,
    meetingEmails: generalInformations.confirmationEmails,
    selectedLanguage: generalInformations.language,
    privateCampaign: generalInformations.privateCampaign,
    agentIds: generalInformations.agents,
    onlineCalendar: generalInformations.onlineCalendar,
    campaignManagerId: generalInformations.campaignManagerId,
    qaReminders: generalInformations.qaReminders,
    campaignType: appointmentInformations.campaignType,
    numberOfMeetingToObtained: appointmentInformations.numberOfAppointment,
    campaignValue: appointmentInformations.value,
    sendMeetingsEmail: appointmentInformations.sendAutomatically,
    active: appointmentInformations.active,
    targetMeetingForDay: appointmentInformations.targetMeetingForDay,
    campaignScheduleText: appointmentInformations.campaignScheduleText || '',
    percentEstimated: appointmentInformations.percentageEstimateAppointment,
    otherRenew: appointmentInformations.otherRenewal.one,
    otherRenew2: appointmentInformations.otherRenewal.two,
    otherRenew3: appointmentInformations.otherRenewal.three,
    campaignHeader: script.scriptFr.scriptHeader,
    campaignScript: script.scriptFr.scriptContent,
    campaignHeaderEn: script.scriptEn.scriptHeader,
    campaignScriptEn: script.scriptEn.scriptContent,
    website: technicalSheet.website,
    technicalSheetAddresses: technicalSheet.campaignAddresses,
    technicalSheetPersons: technicalSheet.campaignManagers,
    technicalSheetTerritories: technicalSheet.campaignTerritories,
    technicalSheetQuestions: technicalSheet.campaignQuestions,
    clipboards,
    meetingMessage: '',
    lnkCampaigns,
  }
}

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    CREATE_CAMPAIGN: (
      state,
      {
        user,
        generalInformations,
        appointmentInformations,
        script,
        clipboards,
        technicalSheet,
        lnkCampaigns,
      },
    ) =>
      new Promise((resolve, reject) => {
        const apiEvent = convertToAPIFormat(
          user,
          generalInformations,
          appointmentInformations,
          script,
          clipboards,
          technicalSheet,
          lnkCampaigns,
        )

        Vue.prototype.$http
          .post('/api/campaigns', apiEvent)
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    GENERATE_CODE: (state, data) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .post(`/api/campaigns/${data.campaignId}/code`)
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    UPDATE_CAMPAIGN: (state, data) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .post(`/api/campaigns/${data.campaignId}`, data)
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    GET_CAMPAIGNS: (state, { paramsCampaign, onlyActive }) =>
      new Promise((resolve, reject) => {
        const params = {
          pageNumber: paramsCampaign.pageNumber,
          pageSize: paramsCampaign.pageSize,
          onlyActive,
          search: paramsCampaign.search,
        }

        Vue.prototype.$http
          .get('/api/campaigns', { params })
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    GET_CAMPAIGN: (state, { campaignId }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .get(`/api/campaigns/${campaignId}`)
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    GET_MANAGERS: () =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .get('/api/campaigns/managers')
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    UPLOAD_SCRIPT_FILE: (state, { campaignId, formData }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .post(`/api/campaigns/${campaignId}/scripts`, formData, {
            headers: {
              'Content-type': 'multipart/form-data',
            },
          })
          .then(res => {
            resolve(res)
          })
          .catch(err => reject(err))
      }),
    DELETE_SCRIPT_FILE: (state, { campaignId, fileName }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .delete(`/api/campaigns/${campaignId}/scripts/${fileName}`)
          .then(res => {
            resolve(res)
          })
          .catch(err => reject(err))
      }),
  },
}
