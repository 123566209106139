<template>
  <b-modal
    id="idle-modal"
    ref="idle-modal"
    centered
    :title="$t('idle-modal.title')"
    :hide-footer="true"
    :no-close-on-backdrop="true"
    :no-close-on-esc="true"
    hide-header-close
    size="normal"
    @show="onShow"
    @hidden="onHidden"
  >
    <div class="d-flex flex-column justify-content-between align-items-center">
      <b-alert
        show
        variant="warning"
      >
        <div class="alert-body text-center">
          {{ $t('idle-modal.warning') }}
        </div>
      </b-alert>
      <p>
        {{ $t('idle-modal.time-left', { seconds: timeLeft / 1000 }) }}
      </p>
    </div>
    <div class="d-flex justify-content-between">
      <b-button
        variant="outline-danger"
        @click="logout"
      >
        {{ $t('actions.logout') }}
      </b-button>
      <b-button
        variant="primary"
        @click="stayConnected"
      >
        {{ $t('idle-modal.stay-connected') }}
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import { BModal, BAlert, BButton } from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { closeSession } from '@/auth/utils'

export default {
  components: {
    BModal,
    BAlert,
    BButton,
  },
  data() {
    return {
      idleInterval: null,
      timeLeft: process.env.VUE_APP_IDLE_TIMEOUT,
    }
  },
  methods: {
    onShow() {
      this.idleInterval = setInterval(() => {
        this.timeLeft -= 1000

        if (this.timeLeft <= 0) {
          this.logout()
        }
      }, 1000)
    },
    onHidden() {
      clearInterval(this.idleInterval)
      this.timeLeft = process.env.VUE_APP_IDLE_TIMEOUT
    },
    stayConnected() {
      const tabsChannel = new BroadcastChannel('idleTabsChannel')
      tabsChannel.postMessage('sessionExtended')
      tabsChannel.close()
    },
    logout() {
      this.$refs['idle-modal'].hide()

      useJwt
        .logout({
          punchOutTime: this.moment(this.punchOutTime).format('YYYY-MM-DDTHH:mm:ssZ'),
        })
        .then(() => {
          this.$logRocket.track('LoggedOut')

          this.$posthog.capture('LoggedOut')

          closeSession(
            () =>
              this.$router.push({ name: 'auth-login', query: { redirect: this.$route.fullPath } }),
            // eslint-disable-next-line function-paren-newline
          )
        })
    },
  },
}
</script>

<!-- Force modal on top -->
<style>
#modal-clipboard___BV_modal_outer_ {
  z-index: 3000 !important;
}

.clipboard-columns > :last-child {
  margin-right: 0 !important;
}
</style>
