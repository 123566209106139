import { getCurrentInstance } from '@vue/composition-api'
import axios from '@axios'
import moment from 'moment'

/**
 * Returns translated string if i18n package is available to Vue
 * If i18n is not configured then it will simply return what is being passed
 * Useful if you don't know if i18n is configured or not
 * Used in @core files to handle absence of i18n without errors
 * @param {String} key i18n key to use for translation
 */
export const t = key => {
  const vm = getCurrentInstance().proxy
  return vm.$t ? vm.$t(key) : key
}

export const _ = null

function setLangHeader(lang) {
  axios.defaults.headers['accept-language'] = lang
  document.querySelector('html').setAttribute('lang', lang)
  moment.locale(lang)
}

export const saveLang = lang => {
  localStorage.setItem('quantum-locale', lang)
  setLangHeader(lang)
}

export const loadLang = () => {
  const lang = localStorage.getItem('quantum-locale') || 'fr'
  setLangHeader(lang)

  return lang
}
