// import constants from '@/libs/utils/constants'
import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    isTasksViewOpened: false,
    isTaskViewMaximized: false,
    filters: {
      search: '',
      projectFilter: null,
      sortDesc: true,
      sortBy: null,
    },
  },
  getters: {},
  mutations: {
    setTasksViewOpened(state, isTasksViewOpened) {
      state.isTasksViewOpened = isTasksViewOpened
    },
    setTaskViewMaximized(state, isTaskViewMaximized) {
      state.isTaskViewMaximized = isTaskViewMaximized
    },
    setFilters(state, filters) {
      state.filters = filters
    },
  },
  actions: {
    SET_TASKS_VIEW_OPENED({ commit }, isTasksViewOpened) {
      commit('setTasksViewOpened', isTasksViewOpened)
    },
    SET_TASK_VIEW_MAXIMIZED({ commit }, isTaskViewMaximized) {
      commit('setTaskViewMaximized', isTaskViewMaximized)
    },
    SET_FILTERS({ commit }, filters) {
      commit('setFilters', filters)
    },
    GET_PROJECT_TEMPLATES: () =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .get('/api/projects/templates')
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    GET_PROJECTS: (state, { paramsProject }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .get('/api/projects', { params: paramsProject })
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    GET_PROJECT: (state, { projectId }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .get(`/api/projects/${projectId}`)
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    CREATE_PROJECT: (state, { project }) =>
      new Promise((resolve, reject) => {
        const projectToCreate = {
          ...project,
          projectTasks: project.projectTasks.map(task => ({
            ...task,
            dueDate: task.dueDate === '' || !task.dueDate ? null : task.dueDate,
          })),
        }

        Vue.prototype.$http
          .post('/api/projects', projectToCreate)
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    DELETE_PROJECT: (state, { projectId }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .delete(`/api/projects/${projectId}`)
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    GET_ASSIGNABLE_USERS: () =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .get('/api/projects/assignable-users')
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    CREATE_TASK: (state, { projectId, task }) =>
      new Promise((resolve, reject) => {
        const taskToCreate = {
          ...task,
          dueDate: task.dueDate === '' || !task.dueDate ? null : task.dueDate,
        }

        Vue.prototype.$http
          .post(`/api/projects/${projectId}/tasks`, taskToCreate)
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    UPDATE_TASK: (state, { projectId, task }) =>
      new Promise((resolve, reject) => {
        const taskToUpdate = {
          ...task,
          dueDate: task.dueDate === '' || !task.dueDate ? null : task.dueDate,
          assignedUserId: task.assignedUserId,
        }

        Vue.prototype.$http
          .put(
            `/api/projects/${projectId}/tasks/${task.projectTaskId}`,
            taskToUpdate,
          )
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    DELETE_TASK: (state, { projectId, taskId }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .delete(`/api/projects/${projectId}/tasks/${taskId}`)
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    CREATE_COMMENT: (state, { projectId, taskId, comment }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .post(`/api/projects/${projectId}/tasks/${taskId}/comments`, comment)
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    GET_PROJECT_TEMPLATE: () =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .get('/api/projects/templates')
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    POST_PROJECT_TEMPLATE: (state, request) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .post('/api/projects/templates', request)
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    DELETE_PROJECT_TEMPLATE: (state, { projectTemplateId }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .delete(`/api/projects/templates/${projectTemplateId}`)
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    UPDATE_PROJECT_TEMPLATE: (
      state,
      { projectTemplateId, name, projectTaskTemplates },
    ) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .put(`/api/projects/templates/${projectTemplateId}`, {
            name,
            projectTaskTemplates,
          })
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    GET_ALL_TODO_TASKS: (state, { assignedUserId }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .get(`/api/projects/tasks/todo/${assignedUserId}`)
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
  },
}
