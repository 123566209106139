import axios from '@axios'
import moment from 'moment'
import recurrenceUtils from '@/libs/utils/recurrence'

function convertToFullCalendar(event) {
  return {
    id: event.activityId,
    title: event.subject,
    start: event.startDate,
    end: event.endDate,
    allDay: false,
    startTime: event.daysOfTheWeekMask !== null ? moment(event.startDate).format('HH:mm') : null,
    endTime: event.daysOfTheWeekMask !== null ? moment(event.endDate).format('HH:mm') : null,
    startRecur: event.daysOfTheWeekMask !== null ? event.startDate : null,
    endRecur: event.daysOfTheWeekMask !== null ? event.endDateRecurrence : null,
    daysOfWeek:
      event.daysOfTheWeekMask !== null ? recurrenceUtils.maskToDays(event.daysOfTheWeekMask) : null,
    extendedProps: {
      location: event.location,
      description: event.activityDescription,
      prospectContact: {
        mobile: event.prospectContactMobile,
      },
      prospectContactName: event.prospectContactName,
      prospectContactMobile: event.prospectContactMobile,
      confirmViaSMS: event.confirmViaSMS,
      campaignLnkProspectId: event.campaignLnkProspectId,
      prospectName: event.prospectName,
      campaignName: event.campaignName,
      state: event.state,
      recurrenceEnabled: event.daysOfTheWeekMask !== null,

      // We dupplicate these since recurring events dismiss the real ones
      daysOfWeek:
        event.daysOfTheWeekMask !== null ? recurrenceUtils.maskToDays(event.daysOfTheWeekMask) : [],
      start: event.startDate,
      end: event.endDate,
      endRecur: event.endDateRecurrence,
      meetingClientStatusId: event.meetingClientStatusId,
    },
  }
}

function convertToAPIFormat(event) {
  return {
    subject: event.title,
    activityDescription: event.extendedProps.description,
    location: event.extendedProps.location,
    prospectContactId: event.extendedProps.prospectContact
      ? event.extendedProps.prospectContact.prospectContactId
      : null,
    prospectContactMobile: event.extendedProps.prospectContactMobile,
    confirmViaSMS: event.extendedProps.confirmViaSMS,
    campaignLnkProspectId: event.extendedProps.campaignLnkProspectId,
    startDate: moment(event.start).toISOString(true),
    endDate: moment(event.end).toISOString(true),
    state: event.extendedProps.state || 0,
    /* eslint-disable indent */
    endDateRecurrence: event.extendedProps.recurrenceEnabled
      ? moment(event.endRecur)
          .hour(1) // Small hack to force the recurrence to be inclusive
          .toISOString(true)
      : null,
    /* eslint-enable indent */
    daysOfTheWeekMask: event.extendedProps.recurrenceEnabled
      ? recurrenceUtils.daysToMask(event.daysOfWeek)
      : null,
  }
}

function convertToAPIUpdateFormat(event) {
  return {
    agentId: event.agentId,
    subject: event.subject,
    prospectContactName: event.prospectContactName,
    prospectContactTitle: event.prospectContactTitle,
    prospectContactPhone: event.prospectContactPhone,
    prospectContactMobile: event.prospectContactMobile,
    prospectContactEmail: event.prospectContactEmail,
    confirmViaSMS: event.confirmViaSMS,
    location: event.location,
    startDate: moment(event.startDate).toISOString(true),
    endDate: moment(event.endDate).toISOString(true),
    activityDescription: event.activityDescription,
    approved: event.approved,
    isApprovalBlocked: event.isApprovalBlocked,
    approvalBlockedReason: event.approvalBlockedReason,
  }
}

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    FETCH_EVENTS(ctx, { campaignId, start, end, filter }) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/api/campaigns/${campaignId}/meetings?startDate=${start}&endDate=${end}&filter=${filter}`,
          )
          .then(response => {
            // prettier-ignore
            const events = response.data.data.map(event => convertToFullCalendar(event))

            return resolve(events)
          })
          .catch(error => reject(error))
      })
    },
    FETCH_ONE(ctx, { campaignId, meetingId }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/campaigns/${campaignId}/meetings/${meetingId}`)
          .then(response => {
            const event = convertToFullCalendar(response.data.data)

            return resolve(event)
          })
          .catch(error => reject(error))
      })
    },
    ADD_EVENT(ctx, { campaignId, event }) {
      return new Promise((resolve, reject) => {
        const apiEvent = convertToAPIFormat(event)

        axios
          .post(`/api/campaigns/${campaignId}/meetings`, apiEvent)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    UPDATE_EVENT(ctx, { campaignId, event }) {
      return new Promise((resolve, reject) => {
        const apiEvent = convertToAPIFormat(event)

        axios
          .post(`/api/campaigns/${campaignId}/meetings/${event.id}`, apiEvent)
          .then(response => resolve(convertToFullCalendar(response.data.data)))
          .catch(error => reject(error))
      })
    },
    UPDATE_MEETING_APPROVAL(ctx, { campaignId, event }) {
      return new Promise((resolve, reject) => {
        const apiEvent = convertToAPIUpdateFormat(event)

        axios
          .post(`/api/campaigns/${campaignId}/meetings/${event.id}`, apiEvent)
          .then(response => resolve(convertToFullCalendar(response.data.data)))
          .catch(error => reject(error))
      })
    },
    REMOVE_EVENT(ctx, { campaignId, id, reason }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/campaigns/${campaignId}/meetings/${id}`, {
            data: { reason },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    CONFIRM_EVENT(ctx, { campaignId, id }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/api/campaigns/${campaignId}/meetings/${id}/confirm`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    FETCH_MEETING_APPROVAL() {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/meetings/waiting-for-approval')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    FETCH_MEETING_TO_APPROVE(ctx, { meetingId }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/meetings/waiting-for-approval/${meetingId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    ADD_DISPONIBILITY(ctx, { campaignId, event }) {
      return new Promise((resolve, reject) => {
        const apiEvent = convertToAPIFormat(event)

        axios
          .post(`/api/campaigns/${campaignId}/meetings/disponibilities`, apiEvent)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    UPDATE_DISPONIBILITY(ctx, { campaignId, event }) {
      return new Promise((resolve, reject) => {
        const apiEvent = convertToAPIFormat(event)

        axios
          .post(`/api/campaigns/${campaignId}/meetings/disponibilities/${event.id}`, apiEvent)
          .then(response => resolve(convertToFullCalendar(response.data.data)))
          .catch(error => reject(error))
      })
    },
    REMOVE_DISPONIBILITY(ctx, { campaignId, id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/campaigns/${campaignId}/meetings/disponibilities/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    UPDATE_CAMPAIGN_VALUE(ctx, { campaignId, meetingId, campaignValue }) {
      return new Promise((resolve, reject) => {
        axios
          .patch(
            `/api/campaigns/${campaignId}/meetings/${meetingId}`,
            [
              {
                op: 'replace',
                path: '/campaignValue',
                value: campaignValue,
              },
            ],
            {
              headers: {
                'Content-Type': 'application/json-patch+json',
              },
            },
          )
          .then(response => resolve(convertToFullCalendar(response.data.data)))
          .catch(error => reject(error))
      })
    },
    UPDATE_CAMPAIGN_NOTES(ctx, { campaignId, meetingId, bonusNotes }) {
      return new Promise((resolve, reject) => {
        axios
          .patch(
            `/api/campaigns/${campaignId}/meetings/${meetingId}`,
            [
              {
                op: 'replace',
                path: '/bonusNotes',
                value: bonusNotes,
              },
            ],
            {
              headers: {
                'Content-Type': 'application/json-patch+json',
              },
            },
          )
          .then(response => resolve(convertToFullCalendar(response.data.data)))
          .catch(error => reject(error))
      })
    },
  },
}
