import Vue from 'vue'

export default {
  namespaced: true,
  state: {},
  getters: {},
  actions: {
    GET_ALL_PROVINCES: (context, { params }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .get('/api/locations/provinces', { params })
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    GET_PROVINCE: (context, { provinceId }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .get(`/api/locations/provinces/${provinceId}`)
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    CREATE_PROVINCE: (context, { data }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .post('/api/locations/provinces', data)
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    UPDATE_PROVINCE: (context, { provinceId, data }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .put(`/api/locations/provinces/${provinceId}`, data)
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    DELETE_PROVINCE: (context, { provinceId, data }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .delete(`/api/locations/provinces/${provinceId}`, { data })
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    GET_ALL_REGIONS: (context, { params }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .get('/api/locations/regions', { params })
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    GET_REGION: (context, { regionId }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .get(`/api/locations/regions/${regionId}`)
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    CREATE_REGION: (context, { data }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .post('/api/locations/regions', data)
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    UPDATE_REGION: (context, { regionId, data }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .put(`/api/locations/regions/${regionId}`, data)
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    DELETE_REGION: (context, { regionId, data }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .delete(`/api/locations/regions/${regionId}`, { data })
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    GET_ALL_CITIES: (context, { params }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .get('/api/locations/cities', { params })
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    GET_CITY: (context, { cityId }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .get(`/api/locations/cities/${cityId}`)
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    CREATE_CITY: (context, { data }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .post('/api/locations/cities', data)
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    UPDATE_CITY: (context, { cityId, data }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .put(`/api/locations/cities/${cityId}`, data)
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    DELETE_CITY: (context, { cityId, data }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .delete(`/api/locations/cities/${cityId}`, { data })
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    GET_ALL_POSTAL_CODES: (context, { params }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .get('/api/locations/postal-codes', { params })
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    GET_POSTAL_CODE: (context, { postalCodeId }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .get(`/api/locations/postal-codes/${postalCodeId}`)
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    CREATE_POSTAL_CODE: (context, { data }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .post('/api/locations/postal-codes', data)
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    UPDATE_POSTAL_CODE: (context, { postalCodeId, data }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .put(`/api/locations/postal-codes/${postalCodeId}`, data)
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    DELETE_POSTAL_CODE: (context, { postalCodeId, data }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .delete(`/api/locations/postal-codes/${postalCodeId}`, { data })
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
  },
}
