<script>
import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import constants from '@/libs/utils/constants'
import helpers from '@/libs/utils/helpers'

export default {
  components: {
    ToastificationContent,
  },
  beforeDestroy() {
    this.$notificationHub.$off('basic-notification', this.notificationReceived)
    this.$notificationHub.$off('import-notification', this.importationProgress)
  },
  methods: {
    buildAndStartNotificationHub() {
      this.$notificationHub
        .start(useJwt.getToken())
        .then(() => this.assignHubEvents())
        // eslint-disable-next-line no-console
        .catch(console.error)
      this.$punchHub.start(useJwt.getToken())
        // eslint-disable-next-line no-console
        .catch(console.error)
      this.$actionHub.start(useJwt.getToken())
        // eslint-disable-next-line no-console
        .catch(console.error)
    },
    assignHubEvents() {
      this.$notificationHub.$on('basic-notification', this.notificationReceived)
      this.$notificationHub.$on('import-notification', this.importationProgress)
    },
    notificationReceived(data) {
      // Instead of using the action, we commit directly since
      // the notification has already been created on the server (this is from the hub)
      this.$store.commit('notifications/createNewNotificationInNavbar', data)
      if (data.showToast) {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: this.$t(data.title, {
                ...data.extraArgs,
              }),
              icon: data.icon,
              text: this.$t(data.description, {
                ...data.extraArgs,
              }),
              date: this.moment().format('YYYY-MM-DD HH:mm:ss'),
              variant: helpers.getNotificationVariantFromId(data.variant),
              onClick: () => {
                if (!this.$route.path.includes(data.pathToOpen)) {
                  this.$store.dispatch('prospects/RESET_SELECTED_PROSPECT')
                  this.$router.push(data.pathToOpen)
                }
              },
            },
          },
          {
            timeout: 7500
          }
        )
      }
    },
    importationProgress(data) {
      // Instead of using the action, we commit directly since
      // the notification has already been created on the server (this is from the hub)
      switch (data.extraArgs.importationState) {
        case constants.notificationImportationState.Start:
          this.$store.commit(
            'notifications/createNewImportNotificationInNavbar',
            data,
          )
          break

        case constants.notificationImportationState.Update:
        case constants.notificationImportationState.FinishOnError:
        case constants.notificationImportationState.FinishOnSuccess:
          this.$root.$emit('importation-updated', data)
          this.$store.commit(
            'notifications/updateImportationNotification',
            data,
          )
          break

        default:
          break
      }
    },
  },
}
</script>
