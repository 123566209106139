import Vue from 'vue'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    GET_GENERIC_OTHER_RENEWS: (state, params) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .get('/api/other-renews', { params })
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    GET_SPECIFIC_OTHER_RENEWS: (state, { worksheetId }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .get(`/api/worksheets/${worksheetId}/other-renews`)
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    CREATE_SPECIFIC_OTHER_RENEWS: (state, { worksheetId, params }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .post(`/api/worksheets/${worksheetId}/other-renews`, params)
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    UPDATE_SPECIFIC_OTHER_RENEWS: (
      state,
      { worksheetId, prospectLnkOtherRenewId, params },
    ) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .put(
            `/api/worksheets/${worksheetId}/other-renews/${prospectLnkOtherRenewId}`,
            params,
          )
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
  },
}
