import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    activeCampaign: null,
  },
  getters: {
    activeCampaign: state => state.activeCampaign,
    activeCampaignId: state => state.activeCampaign.campaignId,
  },
  mutations: {
    setActiveCampaign(state, campaign) {
      state.activeCampaign = campaign
    },
  },
  actions: {
    SET_ACTIVE_CAMPAIGN({ commit }, campaign) {
      commit('setActiveCampaign', campaign)
    },
    GET_CAMPAIGNS: (context, { pageNumber, pageSize }) =>
      new Promise((resolve, reject) => {
        const params = {
          pageNumber,
          pageSize,
        }

        Vue.prototype.$http
          .get('/api/client-portal/campaigns', { params })
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    GET_CAMPAIGN_BY_CODE: (context, { campaignCode }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .get(`/api/client-portal/campaigns/${campaignCode}`)
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    VERIFY_CAMPAIGN_ACCESS: (context, { campaignId }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .get(`/api/client-portal/campaigns/${campaignId}/verify-access`)
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    ASSOCIATE_CAMPAIGN: (context, { campaignCode }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .post('/api/client-portal/campaigns/associate', { campaignCode })
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    UPDATE_MEETING_CLIENT_STATUS: (context, { campaignId, meetingId, meetingClientInfo }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .post(`/api/campaigns/${campaignId}/meetings/${meetingId}/status`, meetingClientInfo)
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
  },
}
