import Vue from 'vue'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    GET_MEETING: (state, { meetingCode }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .get(`/api/rsvp/${meetingCode}`)
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    CONFIRM_MEETING: (state, { meetingCode }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .post(`/api/rsvp/${meetingCode}/confirm`)
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    CANCEL_MEETING: (state, { meetingCode, reason }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .post(`/api/rsvp/${meetingCode}/cancel`, { reason })
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
  },
}
