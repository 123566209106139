/* eslint-disable no-param-reassign */
import posthog from 'posthog-js'

export default {
  install(Vue) {
    const isProduction = process.env.NODE_ENV === 'production'

    Vue.prototype.$posthog = posthog.init(process.env.VUE_APP_POSTHOG_TOKEN, {
      api_host: 'https://app.posthog.com',
      session_recording: {
        maskAllInputs: false,
        maskInputOptions: {
          password: true,
        },
      },
      // Do not record sessions in development
      disable_session_recording: !isProduction,
      opt_out_capturing_by_default: !isProduction,
    })

    try {
      if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') {
        Vue.prototype.$posthog.opt_out_capturing()
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
    }
  },
}
