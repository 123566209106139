import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    pageNumber: 0,
    pageSize: 0,
    totalPages: 0,
    totalRecords: 0,
    campaignLnkProspects: [],
    currentCampaignLnkProspectIndexSelected: -1,
    areFiltersLoading: false,
    campaignFilters: {},
    campaignFiltersOptions: {},
    // use to invalidate filter to fetch all from API instead of the cache
    // if the filter is invalid, it means that no data has been fetch from
    // filters
    validFilter: false,
  },
  getters: {
    currentSelectedClnkProspectId: state =>
      state.campaignLnkProspects[state.currentCampaignLnkProspectIndexSelected]
        .campaignLnkProspectId,
    getSanitizedFilters: state => {
      const filteredParam = {
        pageNumber: state.pageNumber,
        pageSize: state.pageSize,
      }
      const filteredBody = {
        search: state.campaignFilters.search,
        activeOnly: state.campaignFilters.activeOnly || true,
        userId: state.campaignFilters.employee ? state.campaignFilters.employee.id : undefined,
        turnover: state.campaignFilters.minRevenues ? state.campaignFilters.minRevenues : 0,
        turnoverTo: state.campaignFilters.maxRevenues
          ? state.campaignFilters.maxRevenues
          : undefined,
        employee: state.campaignFilters.minEmployeeNumber
          ? state.campaignFilters.minEmployeeNumber
          : 0,
        employeeTo: state.campaignFilters.maxEmployeeNumber
          ? state.campaignFilters.maxEmployeeNumber
          : undefined,
        myData: false,
        confirmedBy: state.campaignFilters.confirmedBy
          ? state.campaignFilters.confirmedBy.id
          : undefined,
        sortType: state.campaignFilters.sortType
          ? /* eslint-disable indent */
            (() => {
              switch (state.campaignFilters.sortType) {
                case 'gridFollowUpDate':
                  return 1
                case 'gridLastUpdatedOn':
                  return 2
                case 'gridMeetingDate':
                  return 3
                case 'username':
                  return 4
                case 'gridCampaign':
                  return 5
                case 'gridProspect':
                  return 6
                case 'status':
                  return 7
                case 'city':
                  return 8
                case 'province':
                  return 9
                case 'sic':
                  return 10
                case 'fic':
                  return 11
                default:
                  return -1
              }
            })()
          : -1,
        /* eslint-enable indent */
        sortOrder: state.campaignFilters.sortOrder ? -1 : 1, // -1 = DESC | 1 = ASC
        campaignIdsList:
          !state.campaignFilters.campaign ||
          state.campaignFilters.campaign.map(x => x.id)[0] !== null
            ? state.campaignFilters.campaign.map(x => x.id)
            : undefined,
        statusIdsList:
          state.campaignFilters.status !== undefined
            ? state.campaignFilters.status.map(x => x.id)
            : undefined,
        codesSICList:
          state.campaignFilters.sic !== undefined
            ? state.campaignFilters.sic.map(x => x.id)
            : undefined,
        codesFICList:
          state.campaignFilters.fic !== undefined
            ? state.campaignFilters.fic.map(x => x.id)
            : undefined,
        customers1List: [-1],
        customers2List: [-1],
        citiesList: state.campaignFilters.city.map(x => x.id),
        regionList: state.campaignFilters.region
          ? state.campaignFilters.region.map(x => x.id)
          : undefined,
        provinceList: state.campaignFilters.province.map(x => x.id),
        dateFilter: state.campaignFilters.dateFilter ? state.campaignFilters.dateFilter : 0,
        startDate: state.campaignFilters.fromDate,
        endDate: state.campaignFilters.toDate,
        nbEmployeeModification: state.campaignFilters.employeeNumberModifiedSince,
        radiusMap: state.campaignFilters.map ? state.campaignFilters.map.radius : undefined,
        centerMap: state.campaignFilters.map ? state.campaignFilters.map.center : undefined,
      }
      if (filteredParam.pageNumber === undefined || filteredParam.pageNumber === '') {
        filteredParam.pageNumber = 1
      }
      if (filteredParam.pageSize === undefined || filteredParam.pageSize === '') {
        filteredParam.pageSize = 35
      }
      if (filteredBody.search === undefined || filteredBody.search === '') {
        delete filteredBody.search
      }
      if (filteredBody.activeOnly === undefined) {
        delete filteredBody.activeOnly
      }
      if (filteredBody.userId === undefined) {
        delete filteredBody.userId
      }
      if (
        filteredBody.turnover === undefined ||
        filteredBody.turnover === 0 ||
        filteredBody.turnover === ''
      ) {
        delete filteredBody.turnover
      }
      if (filteredBody.turnoverTo === undefined || filteredBody.turnoverTo === '') {
        delete filteredBody.turnoverTo
      }
      if (
        filteredBody.employee === undefined ||
        filteredBody.employee === 0 ||
        filteredBody.employee === ''
      ) {
        delete filteredBody.employee
      }
      if (filteredBody.employeeTo === undefined || filteredBody.employeeTo === '') {
        delete filteredBody.employeeTo
      }
      if (filteredBody.campaignId === undefined) {
        delete filteredBody.campaignId
      }
      if (filteredBody.campaignIdsList === undefined || filteredBody.campaignIdsList === '') {
        filteredBody.campaignIdsList = [-1]
      }
      if (
        filteredBody.statusIdsList === undefined ||
        filteredBody.statusIdsList === '' ||
        filteredBody.statusIdsList.includes(-1)
      ) {
        filteredBody.statusIdsList = [-1]
      }
      if (
        filteredBody.codesSICList === undefined ||
        filteredBody.codesSICList === '' ||
        filteredBody.codesSICList.includes(-1)
      ) {
        delete filteredBody.codesSICList
      }
      if (
        filteredBody.codesFICList === undefined ||
        filteredBody.codesFICList === '' ||
        filteredBody.codesFICList.includes(-1)
      ) {
        delete filteredBody.codesFICList
      }
      if (
        filteredBody.customers1List === undefined ||
        filteredBody.customers1List === '' ||
        filteredBody.customers1List.includes(-1)
      ) {
        delete filteredBody.customers1List
      }
      if (
        filteredBody.customers2List === undefined ||
        filteredBody.customers2List === '' ||
        filteredBody.customers2List.includes(-1)
      ) {
        delete filteredBody.customers2List
      }
      if (
        filteredBody.citiesList === undefined ||
        filteredBody.citiesList === '' ||
        filteredBody.citiesList.includes(-1)
      ) {
        delete filteredBody.citiesList
      }
      if (
        filteredBody.regionList === undefined ||
        filteredBody.regionList === '' ||
        filteredBody.regionList.includes(-1)
      ) {
        delete filteredBody.regionList
      }
      if (
        filteredBody.provinceList === undefined ||
        filteredBody.provinceList === '' ||
        filteredBody.provinceList.includes(-1)
      ) {
        delete filteredBody.provinceList
      }
      if (filteredBody.dateFilter === undefined) {
        delete filteredBody.dateFilter
      }
      if (filteredBody.startDate === undefined || filteredBody.startDate === '') {
        delete filteredBody.startDate
      }
      if (filteredBody.endDate === undefined || filteredBody.endDate === '') {
        delete filteredBody.endDate
      }
      if (
        filteredBody.nbEmployeeModification === undefined ||
        filteredBody.nbEmployeeModification === ''
      ) {
        delete filteredBody.nbEmployeeModification
      }
      if (filteredBody.radiusMap === undefined) {
        delete filteredBody.radiusMap
      }
      if (filteredBody.centerMap === undefined) {
        delete filteredBody.centerMap
      }
      return { param: filteredParam, body: filteredBody }
    },
    areCampaignFiltersSaved: state =>
      state.campaignFilters !== undefined && Object.entries(state.campaignFilters).length > 0,
  },
  mutations: {
    setFilterLoading(state) {
      state.areFiltersLoading = true
    },
    storeFilters(state, payload) {
      state.validFilter = false
      state.areFiltersLoading = false
      state.campaignFilters = payload
    },
    storeSortFilter(state, { sortType, sortOrder }) {
      state.validFilter = false

      // Force reactivity
      state.campaignFilters = { ...state.campaignFilters, sortType, sortOrder }
    },
    storeFiltersOptions(state, payload) {
      state.campaignFiltersOptions = payload
    },
    storeCampaignLnkProspects(state, payload) {
      state.pageNumber = payload.pageNumber
      state.pageSize = payload.pageSize
      state.totalPages = payload.totalPages
      state.totalRecords = payload.totalRecords
      state.campaignLnkProspects = payload.campaignLnkProspects
    },
    selectAProspect(state, index) {
      if (index < state.pageSize) {
        state.currentCampaignLnkProspectIndexSelected = index
      } else {
        state.currentCampaignLnkProspectIndexSelected = -1
      }
    },
    resetSelectedProspect(state) {
      state.currentCampaignLnkProspectIndexSelected = -1
    },
  },
  actions: {
    /**
     * Get prospects from API
     * @param {*} None
     * @param { params }
     * @returns Promise of the data loaded
     */
    GET_PROSPECTS: ({ state, commit, getters }, { paramsProspect, force }) =>
      new Promise((resolve, reject) => {
        // If filters are not already cached, reject the call
        if (Object.entries(state.campaignFilters).length === 0) {
          return resolve({
            data: {
              data: [],
              pageSize: 0,
              pageNumber: 1,
              totalRecords: 0,
              totalPages: 0,
            },
          })
        }

        // If filters are already cached and the same ones, return the cached data
        if (state.validFilter && paramsProspect.pageNumber === state.pageNumber && !force) {
          return resolve({
            data: {
              data: state.campaignLnkProspects,
              pageSize: state.campaignLnkProspects.length,
              pageNumber: state.pageNumber,
              totalRecords: state.totalRecords,
              totalPages: state.totalPages,
            },
          })
        }

        const params = {
          pageNumber: paramsProspect.pageNumber,
          pageSize: paramsProspect.pageSize,
        }

        if (force) {
          params.timestamp = new Date().getTime()
        }

        return Vue.prototype.$http
          .post('/api/worksheets/search', getters.getSanitizedFilters.body, {
            params,
          })
          .then(res => {
            commit('storeCampaignLnkProspects', {
              pageNumber: res.data.pageNumber,
              pageSize: res.data.pageSize,
              totalRecords: res.data.totalRecords,
              totalPages: res.data.totalPages,
              campaignLnkProspects: res.data.data,
            })

            let sortBy = null
            switch (state.campaignFilters.sortType) {
              case 1:
                sortBy = 'gridFollowUpDate'
                break
              case 2:
                sortBy = 'gridLastUpdatedOn'
                break
              case 3:
                sortBy = null
                break
              case 4:
                sortBy = 'username'
                break
              case 5:
                sortBy = 'gridCampaign'
                break
              case 6:
                sortBy = 'gridProspect'
                break
              case 7:
                sortBy = 'status'
                break
              case 8:
                sortBy = 'city'
                break
              case 9:
                sortBy = 'province'
                break
              case 10:
                sortBy = 'sic'
                break
              case 11:
                sortBy = 'fic'
                break
              default:
                sortBy = null
                break
            }

            res.sortBy = sortBy
            res.sortDesc = state.campaignFilters.sortOrder === -1

            state.validFilter = true
            return resolve(res)
          })
          .catch(err => {
            commit('prospects/storeFilters', {})
            commit('storeCampaignLnkProspects', {
              pageNumber: -1,
              pageSize: 0,
              totalPages: 0,
              campaignLnkProspects: [],
            })
            return reject(err)
          })
      }),
    SELECT_NEXT_PROSPECT: ({ state, getters, commit, dispatch }) =>
      new Promise((resolve, reject) => {
        if (
          state.currentCampaignLnkProspectIndexSelected === state.pageSize - 1 &&
          state.pageNumber < state.totalPages
        ) {
          // If need next page
          dispatch('GET_PROSPECTS', {
            paramsProspect: { pageNumber: state.pageNumber + 1, pageSize: 35 },
          })
            .then(() => {
              commit('selectAProspect', 0)
              resolve(getters.currentSelectedClnkProspectId)
            })
            .catch(err => reject(err))
        } else if (
          state.currentCampaignLnkProspectIndexSelected === state.pageSize - 1 &&
          state.pageNumber === state.totalPages
        ) {
          // If max prospect reached
          dispatch('GET_PROSPECTS', {
            paramsProspect: { pageNumber: 1, pageSize: 35 },
          })
            .then(() => {
              commit('selectAProspect', 0)
              resolve(getters.currentSelectedClnkProspectId)
            })
            .catch(err => reject(err))
        } else {
          // Select normally
          commit('selectAProspect', state.currentCampaignLnkProspectIndexSelected + 1)
          resolve(getters.currentSelectedClnkProspectId)
        }
      }),
    SELECT_PREVIOUS_PROSPECT: ({ state, getters, commit, dispatch }) =>
      new Promise((resolve, reject) => {
        if (state.currentCampaignLnkProspectIndexSelected === 0 && state.pageNumber > 1) {
          dispatch('GET_PROSPECTS', {
            paramsProspect: { pageNumber: state.pageNumber - 1, pageSize: 35 },
          })
            .then(() => {
              commit('selectAProspect', state.pageSize - 1)
              resolve(getters.currentSelectedClnkProspectId)
            })
            .catch(err => reject(err))
        } else if (state.currentCampaignLnkProspectIndexSelected === 0 && state.pageNumber === 1) {
          // If min prospect reached
          dispatch('GET_PROSPECTS', {
            paramsProspect: { pageNumber: state.totalPages, pageSize: 35 },
          })
            .then(() => {
              commit('selectAProspect', state.pageSize - 1)
              resolve(getters.currentSelectedClnkProspectId)
            })
            .catch(err => reject(err))
        } else {
          // Select normally
          commit('selectAProspect', state.currentCampaignLnkProspectIndexSelected - 1)
          resolve(getters.currentSelectedClnkProspectId)
        }
      }),
    PUT_PROSPECT_INFO: ({ commit }, { prospectId, putPayload }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .put(`/api/prospects/${prospectId}`, putPayload)
          .then(res => {
            commit(
              'worksheet/storeProspectInfo',
              {
                prospectInfo: res.data.data,
              },
              { root: true },
            )
            resolve(res)
          })
          .catch(err => {
            reject(err)
          })
      }),
    SELECT_A_PROSPECT: ({ commit }, prospectIndex) =>
      new Promise(resolve => {
        commit('selectAProspect', prospectIndex)
        commit('worksheet/setProspectsType', 'prospects', {
          root: true,
        })
        resolve(prospectIndex)
      }),
    RESET_SELECTED_PROSPECT: ({ commit }) =>
      new Promise(resolve => {
        commit('resetSelectedProspect')
        resolve()
      }),
    CLEAR_FILTERS: ({ commit }) => {
      commit('storeFilters', {})
      commit('storeFiltersOptions', {})
    },
    GET_PROSPECTS_IDS: ({ getters }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .post('/api/worksheets/search?shouldPaginate=false', {
            ...getters.getSanitizedFilters.body,
          })
          .then(res => {
            const prospects = res.data.data.map(prospect => ({
              campaignLnkProspectId: prospect.campaignLnkProspectId,
              gridProspect: prospect.gridProspect,
            }))

            resolve(prospects)
          })
          .catch(err => {
            reject(err)
          })
      }),
    EXPORT_PROSPECTS: ({ getters }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .post('/api/worksheets/export', getters.getSanitizedFilters.body)
          .then(res => {
            resolve(res.data)
          })
          .catch(err => {
            reject(err)
          })
      }),
  },
}
