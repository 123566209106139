import Vue from 'vue'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    /**
     * Create a new contact
     * @param campaignLnkProspectId The unique ID between a campaign and a prospect
     * @param contact The new contact to create with the API
     * @returns The newly created contact
     */
    CREATE_CONTACT: (state, { campaignLnkProspectId, contact }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .post(`/api/worksheets/${campaignLnkProspectId}/contacts`, contact)
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    /**
     * Update a contact
     * @param prospectContactId The ID of the updated contact
     * @param campaignLnkProspectId The unique ID between a campaign and a prospect
     * @param contact An updated version of the contact to send to the API
     * @returns The updated contact
     */
    UPDATE_CONTACT: (state, { campaignLnkProspectId, prospectContactId, contact }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .put(`/api/worksheets/${campaignLnkProspectId}/contacts/${prospectContactId}`, contact)
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    /**
     * Delete a contact
     * @param prospectContactId The ID of the updated contact
     * @param campaignLnkProspectId The unique ID between a campaign and a prospect
     * @returns 204 - No Content
     */
    DELETE_CONTACT: (state, { campaignLnkProspectId, prospectContactId }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .delete(`/api/worksheets/${campaignLnkProspectId}/contacts/${prospectContactId}`)
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
  },
}
