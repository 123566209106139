import constants from '@/libs/utils/constants'
import moment from 'moment'
import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    allNotifications: [],
    currentIdCounter: 0,
  },
  getters: {
    genericNotifications(state) {
      return state.allNotifications.filter(
        notification =>
          !notification.title.includes('ticket') && !notification.title.includes('support-ticket'),
      )
    },
    unseenNotifications(state, getters) {
      return getters.genericNotifications.filter(x => !x.viewedOn)
    },
    ticketNotifications(state) {
      return state.allNotifications.filter(
        x => x.title.includes('ticket') && !x.title.includes('support-ticket'),
      )
    },
    unseenTicketNotifications(state, getters) {
      return getters.ticketNotifications.filter(x => !x.viewedOn)
    },
    supportTicketNotifications(state) {
      return state.allNotifications.filter(x => x.title.includes('support-ticket'))
    },
    unseenSupportTicketNotifications(state, getters) {
      return getters.supportTicketNotifications.filter(x => !x.viewedOn)
    },
  },
  mutations: {
    setNotifications(state, payload) {
      state.allNotifications = payload
    },
    createNewNotificationInNavbar(state, notification) {
      state.allNotifications.unshift(notification)
    },
    createNewImportNotificationInNavbar(state, notification) {
      state.allNotifications.unshift(notification)
    },
    markAsViewed(state, index) {
      if (state.allNotifications[index]) {
        state.allNotifications[index].viewedOn = moment()
      }
    },
    deleteNotification(state, index) {
      state.allNotifications.splice(index, 1)
    },
    markAllAsSeen(state, notificationsToUpdate) {
      notificationsToUpdate.forEach(x => {
        if (!x.viewedOn) {
          state.allNotifications.find(notif => notif.notificationId === x.notificationId).viewedOn =
            moment()
        }
      })
    },
    deleteAllNotifications(state, notificationsToDelete) {
      state.allNotifications = state.allNotifications.filter(
        item => !notificationsToDelete.some(x => x.notificationId === item.notificationId),
      )
    },
    updateImportationNotification(state, notification) {
      const notif = state.allNotifications.find(
        x => x.extraArgs.importationId === notification.extraArgs.importationId,
      )

      notif.variant = notification.variant
      notif.description = notification.description
      // eslint-disable-next-line prefer-object-spread
      notif.extraArgs = Object.assign({}, notification.extraArgs)
    },
    finishImportSuccess(state, { importationId }) {
      const notif = state.allNotifications.find(x => x.importationId === importationId)

      notif.variant = 'success'
      notif.text = 'notification.import.on-success'
      notif.extraArgs.estimatedTimeRemaining = 0
    },
    finishImportError(state, { importationId }) {
      const notif = state.allNotifications.find(x => x.importationId === importationId)

      notif.variant = 'danger'
      notif.text = 'notification.import.on-error'
      notif.extraArgs.estimatedTimeRemaining = 0
      notif.extraArgs.current = 1
      notif.extraArgs.total = 1
    },
  },
  actions: {
    FETCH_NOTIFICATIONS: ({ commit }) =>
      new Promise(
        (resolve, reject) =>
          Vue.prototype.$http
            .get('/api/notifications')
            .then(response => {
              commit('setNotifications', response.data.data)
              return resolve(response)
            })
            .catch(error => reject(error)),
        // eslint-disable-next-line function-paren-newline
      ),
    CREATE_NEW_NOTIFICATION: (context, payload) => {
      // eslint-disable-next-line no-param-reassign
      if (!payload.type) payload.type = constants.notificationType.Disposable

      return new Promise(
        (resolve, reject) =>
          Vue.prototype.$http
            .post('/api/notifications', payload)
            .then(response => resolve(response)) // The commit will happen in the hub
            .catch(err => reject(err)),
        // eslint-disable-next-line function-paren-newline
      )
    },
    MARK_AS_VIEWED: ({ commit }, { notification, index }) =>
      new Promise(
        (resolve, reject) =>
          Vue.prototype.$http
            .post(`/api/notifications/${notification.notificationId}/mark-as-viewed`)
            .then(response => {
              commit('markAsViewed', index)
              return resolve(response)
            })
            .catch(err => reject(err)),
        // eslint-disable-next-line function-paren-newline
      ),
    DELETE_NOTIFICATION: ({ commit }, { notification, index }) =>
      new Promise(
        (resolve, reject) =>
          Vue.prototype.$http
            .delete(`/api/notifications/${notification.notificationId}`)
            .then(response => {
              commit('deleteNotification', index)
              return resolve(response)
            })
            .catch(err => reject(err)),
        // eslint-disable-next-line function-paren-newline
      ),
    MARK_ALL_AS_VIEWED: ({ commit, getters }, { type }) =>
      new Promise((resolve, reject) => {
        let notifications = []
        if (type === 'ticket') {
          notifications = getters.ticketNotifications
        } else if (type === 'support-ticket') {
          notifications = getters.supportTicketNotifications
        } else {
          notifications = getters.genericNotifications
        }

        return Promise.all(
          notifications.map(
            x => Vue.prototype.$http.post(`/api/notifications/${x.notificationId}/mark-as-viewed`),
            // eslint-disable-next-line function-paren-newline
          ),
        )
          .then(response => {
            commit('markAllAsSeen', notifications)
            return resolve(response)
          })
          .catch(err => reject(err))
      }),
    MARK_AS_VIEWED_RELATED: ({ commit, state }, { name, id }) =>
      new Promise((resolve, reject) => {
        const notifications = state.allNotifications.filter(
          x =>
            x.relatedEntityId === parseInt(id, 10) && x.relatedEntityName === name && !x.viewedOn,
        )

        return Promise.all(
          notifications.map(
            x => Vue.prototype.$http.post(`/api/notifications/${x.notificationId}/mark-as-viewed`),
            // eslint-disable-next-line function-paren-newline
          ),
        )
          .then(response => {
            commit('markAllAsSeen', notifications)
            return resolve(response)
          })
          .catch(err => reject(err))
      }),
    DELETE_ALL_NOTIFICATION: ({ commit, getters }, { type }) =>
      new Promise((resolve, reject) => {
        let notifications = []
        if (type === 'ticket') {
          notifications = getters.ticketNotifications
        } else if (type === 'support-ticket') {
          notifications = getters.supportTicketNotifications
        } else {
          notifications = getters.genericNotifications.filter(
            item =>
              !item.extraArgs ||
              (item.extraArgs &&
                item.extraArgs.importationState !== constants.notificationImportationState.Update &&
                item.extraArgs.importationState !== constants.notificationImportationState.Start),
          )
        }

        return Promise.all(
          notifications.map(
            x => Vue.prototype.$http.delete(`/api/notifications/${x.notificationId}`),
            // eslint-disable-next-line function-paren-newline
          ),
        )
          .then(response => {
            commit('deleteAllNotifications', notifications)
            return resolve(response)
          })
          .catch(err => reject(err))
      }),
  },
}
